
import { AreaApi } from "@/api/area.api";
// import { ClientsApi } from "@/api/clients.api";
import { defineComponent } from "vue";
import {getAreaData} from "@/consts/fields/areas.fields";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";

export default defineComponent({
  mixins: [CharacteristicBase],
  data: () => ({
    regionList: [],
    cityList: [],
    // clientList: [],
    areaTypeList: [],
    divisionList: [],
    regionSearch: "",
    citySearch: "",
    stages: [],
    validationFields:[
        {field: "name", validators: ["required"]},
        {field: "region.name", validators: ["required"]},
        // {field: "division.name", validators: ["required"]},
        {field: "code", validators: ["required"]},
        // {field: "client.name", validators: ["required"]},
    ],
    data: getAreaData()
  }),
  mounted() {
    this.getRegion();
    this.getCity();
    // this.getClients();
    this.getStages();
    this.getAreaType();
    this.getDivision();
    this.getCRUDSettings(73)
  },
  methods: {
    getRegion() {
      AreaApi.getRegionsList("", 0, 100).then((res) => {
        this.regionList = res;
      });
    },
    getCity() {
      AreaApi.getCitiesAsText("", 0, 100).then((res) => {
        this.cityList = res;
      });
    },
    // getClients() {
    //   ClientsApi.applyFilter({}, 10, 0).then((res) => {
    //     this.clientList = res.data;
    //   });
    // },
    getStages() {
      AreaApi.getStages().then((res) => {
        this.stages = res;
      });
    },
    getAreaType() {
      AreaApi.getAreaType().then((res) => {
        this.areaTypeList = res.data;
      });
    },
    getDivision() {
      AreaApi.getDivisions("").then((res) => {
        this.divisionList = res;
      });
    },
    filterOption(input: string, option) {
      return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    },
    onSearchRegion() {
      AreaApi.getRegionsList(this.data.region.name, 0, 10).then((res) => {
        this.regionList = res;
      });
    },
    onSearchCity() {
      AreaApi.getCitiesAsText(this.data.city, 0, 100).then((res) => {
        this.cityList = res;
      });
    },
    onSearchDivision() {
      AreaApi.getDivisions(this.data.division.name).then((res) => {
        this.divisionList = res;
      });
    },
    onSelectRegion(value, option) {
      this.data.region = {
        name: option.value,
        id: option.id,
      };
    },
    // onSelectClient(value, option) {
    //   this.data.client = {
    //     name: option.value,
    //     id: option.id,
    //   };
    // },
    onSelectStage(value, option) {
      this.data.stage = {
        name: option.value,
        id: option.id,
      };
    },
    onSelectDivision(value, option) {
      this.data.division = {
        name: option.value,
        id: option.id
      };
    },
    onSelect(value, option) {
      console.log(value);
      console.log(option);
    },
  },
});
