
import { defineComponent } from "vue";
import SupportTeamCharacteristics from "@/components/support-teams/SupportTeamCharacteristics.vue";
import {SupportTeamApi} from "../../api/supportTeam.api";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase from "@/classes/ViewBase.vue";
import ExecutorUsersList from "@/components/executor-users/ExecutorUsersList.vue";
import ExecutorUsersFilter from "@/components/executor-users/ExecutorUsersFilter.vue";
import EquipmentTypesList from "@/components/equipment-types/EquipmentTypesList.vue";
import EquipmentTypesFilter from "@/components/equipment-types/EquipmentTypesFilter.vue";
import {suffix} from "@/consts/nameSuff";
import {currentUser} from "@/consts/currentUser";
import ContactsList from "@/components/contacts/ContactsList.vue";
import { contactFields } from "@/consts/fields/contact.fields";
import { CounterpartyApi } from "@/api/counterparty.api";

export default defineComponent({
  name: "SupportTeamsView",
  components: {
    ContactsList,
    SupportTeamCharacteristics,
    EditForm,
    DeleteDialog,
    ExecutorUsersFilter,
    ExecutorUsersList,
    EquipmentTypesFilter,
    EquipmentTypesList
  },
  mixins: [ViewBase],
  data: () => ({
    body: {},
    currentUser,
    selectorData: [
      {value: 'Сотрудники', menuCode: 'security', childMenuCode: 'users'},
      {value: 'Типы объектов', menuCode: 'dictionary', childMenuCode: 'types_of_objects'}
    ],
    currentSelect: 'Сотрудники',
    type: 'support-teams',
    contactTypeList: [
      {
        id: 1,
        name: 'Телефон'
      },
      {
        id: 2,
        name: 'Email'
      },
      {
        id: 3,
        name: 'Рабочий телефон'
      },
      {
        id: 4,
        name: 'Telegram'
      },
      {
        id: 5,
        name: 'Skype'
      },
    ],
    contact: {
      search: '',
      fields: contactFields.slice(),
      list: [],
      size: 10,
      page: 0,
      totalCount: 0,
      sortParams: [{sortFieldName: "createTime", direction: "desc"}]
    },
    contactData: {
      contactInfo: '',
      contactType: {
        id: null,
        name: ''
      },
      supportTeam: {
        id: null
      },
    },
    isLoadContacts: false,
    isAddContactDialogOpen: false,
    addButton: false,
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();

    const accessParams = { module: 'dictionary', object: 'support_teams' };
    const tabMap = {
      'tab_common_information_support_teams': '1',
      'tab_relation_support_teams': '2',
      'tab_contacts_support_teams': '3',
    };
    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null)
    if (!this.$refs.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    applyCurrentRelatedFilter() {
      this.prepareDataForList();
      this.isLoad = false;
      let id = this.currentRelatedObject.type == 'executor-users' ? [this.$route.params.id] : this.$route.params.id;
      const key = this.currentRelatedObject.type == 'executor-users' ? 'supportTeamIds' : 'supportTeamId';
      this.currentRelatedObject.get({
        ...this.currentRelatedObject.filter,
        [key]: id,
      }, this.currentRelatedObject.size, this.currentRelatedObject.page)
          .then(res => {
            this.currentRelatedObject.list = [];
            this.currentRelatedObject.totalCount = res.totalCount;
            setTimeout(() => {
              this.currentRelatedObject.list = res.data;
            }, 1);
            this.isLoad = true;
          });
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog("команды поддержки", `команду поддержки ${this.body.name}`);
    },
    getById() {
      SupportTeamApi.getById(this.id).then((res) => {
        suffix.next(' ' + res.name)
        if (res.status?.id) {
          res.isStatus = res.status.id === 1 ? true : false;
          res.status = res.status.id === 1 ? {name: "Активен", id: 1} : {name: "Неактивен", id: 3};
        }
        this.contact.list = res.additionalContacts;

        this.isLoadContacts = true;
        if (res.contacts)
          res.contacts.sort((a, b) => b.contactType.id - a.contactType.id);
        this.body = res;
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const contacts = this.$refs.characteristics.data.contacts;
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      let contractsInfo = [];
      Object.keys(contacts).forEach(key => {
        contractsInfo.push(contacts[key]);
      });
      characteristics.contacts = contractsInfo;
      characteristics.status = characteristics.isStatus ? {name: "Активен", id: 1} : {name: "Неактивен", id: 3};

      SupportTeamApi.save(
          characteristics
      ).then(() => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.getById();
      });
    },
    deleteObject() {
      SupportTeamApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
    closeDialog() {
      this.contactData.contactType.id = null;
      this.contactData.contactType.name = '';
      this.contactData.contactInfo = '';
      this.isAddContactDialogOpen = false;
    },
    openAddContactDialog() {
      this.isAddContactDialogOpen = true;
    },
    addContact() {
      this.addButton = true;
      this.contactData.supportTeam.id = this.id
      SupportTeamApi.addAdditionalContact(this.contactData)
        .then(() => {
          this.getById();
          this.closeDialog();
        })
    },
    selectContactType(ev, option) {
      this.contactData.contactType.id = option.id;
    },
  },
});
