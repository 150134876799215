
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getHealthModelRuleData} from "@/consts/fields/health-model-rule.fields";
import {ClassifilesApi} from "@/api/classifires.api";
import {MetricApi} from "@/api/metric.api";
import {HealthModelRuleApi} from "@/api/health-model-rule.api";
import {monitoringObjectsFields} from "@/consts/fields/monitoring-objects.fields";

export default defineComponent ({
  name: "HealthModelRuleCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getHealthModelRuleData(),
    validationFields:[
      {field: "name", validators: ["required"]},
    ],
    expressionApplianceTypeList: [{name: 'Времени', value:'TIME'}, {name: 'Последнему значению' , value: 'LAST_VALUE'}],
    characteristicsConditionList: [{name: 'И', value: 'and'}, {name:'ИЛИ', value: 'or'}],
    priorityList: [],
    incidentTypeList: [],
    supportTimeList: [],
    metricList: [],
    testData: {
      checkOpen: 'Времени',
      checkClose: 'Времени',
      expressionOpen: [
        {id:1, metricId:12, metricName: 'Доступность', condition: '>', limit: 'Порог1'},
        {id:2, metricId:10, metricName: 'MTU', condition: '<', limit: 'Порог3'}
      ],
      expressionClose: [
        {id:1, metricId:12, metricName: 'Доступность', condition: '<', limit: 'Порог1'},
        {id:2, metricId:10, metricName: 'MTU', condition: '>', limit: 'Порог3'}
      ]
    },
    conditionList: [
      {value: '>'},
      {value: '<'},
      {value: '>='},
      {value: '<='},
    ],
    incidentOpenJsonData: {expression: []},
    incidentCloseJsonData: {expression: []},
    monitoringCharacteristicsJson: {
      type: '',
      characteristics: []
    },
    propList: [],
    propValueList: [],
    limitNameList: [],
    type: ''
  }),
  mounted() {
    this.getIncidentFilterList();
    this.getMetricList();
    this.getCharacteristicsField();
    this.getCRUDSettings(82)
    this.getLimitName();
  },
  methods: {
    getIncidentFilterList() {
      ClassifilesApi.getIncidentFilterList().then((res)=> {
        this.priorityList = res.data.priority
        this.incidentTypeList = res.data.types
        this.supportTimeList = res.data.supportTeams
      })
    },
    getMetricList(name?) {
      if (name) {
        MetricApi.applyFilter({name: name}, 500).then((res) => {
          this.metricList = res.data
        })
      }
      else {
        MetricApi.applyFilter({}, 500).then((res) => {
        this.metricList = res.data
      })
      }
    },
    addMetricLimit() {
    this.data.metricLimits.push({
      metricId: null,
      metricName: "",
      limits: []
    })
      console.log(this.data)
    },
    addLimit(index) {
      this.data.metricLimits[index].limits.push({
        id: null,
        name: {},
        value: null
      })
      console.log(this.data)
    },
    deleteMetricLimit(index) {
      this.data.metricLimits.splice(index,1)
    },
    deleteLimit(index,anotherIndex) {
      this.data.metricLimits[index].limits.splice(anotherIndex,1)
    },
    addRuleOpenExpression() {
      this.incidentOpenJsonData.expression.push( {
        id:   this.incidentOpenJsonData.expression.length + 1,
        metricId: null,
        metricName: "",
        condition: "",
        limit: ""
      })
      this.data.ruleOpen.metricLimitCondition = JSON.stringify(this.incidentOpenJsonData)
    },
    addMonitoringParams() {
      this.monitoringCharacteristicsJson.characteristics.push( {
        propName: "",
        propDescription: "",
        propValueName: [],
        propValueId: []
      })
      this.data.monitoringObjectParamExpression= JSON.stringify(this.monitoringCharacteristicsJson)
    },
    deleteMonitoringParams(index) {
      this.monitoringCharacteristicsJson.characteristics.splice(index,1)
      this.data.monitoringObjectParamExpression= JSON.stringify(this.monitoringCharacteristicsJson)
    },
    deleteOpenExpression(index) {
      this.incidentOpenJsonData.expression.splice(index,1)
      this.data.ruleOpen.metricLimitCondition = JSON.stringify(this.incidentOpenJsonData)
    },
    addRuleCloseExpression() {
      this.incidentCloseJsonData.expression.push( {
        id:   this.incidentCloseJsonData.expression.length + 1,
        metricId: null,
        metricName: "",
        condition: "",
        limit: ""
      })
      this.data.ruleClose.metricLimitCondition = JSON.stringify(this.incidentCloseJsonData)
    },
    deleteCloseExpression(index) {
      this.incidentCloseJsonData.expression.splice(index,1)
      this.data.ruleClose.metricLimitCondition = JSON.stringify(this.incidentCloseJsonData)
    },
    selectMetric(e,option) {
      console.log(e)
      console.log(option)
      this.data.metricLimits[option.index].metricId = option.id
    },
    selectOpenMetric(e, option) {
      this.incidentOpenJsonData.expression[option.index].metricId = option.id
      this.data.ruleOpen.metricLimitCondition = JSON.stringify(this.incidentOpenJsonData)

    },
    selectCloseMetric(e, option) {
      this.incidentCloseJsonData.expression[option.index].metricId = option.id
      this.data.ruleClose.metricLimitCondition = JSON.stringify(this.incidentCloseJsonData)
    },
    approveSelect() {
      this.data.ruleOpen.metricLimitCondition = JSON.stringify(this.incidentOpenJsonData)
      this.data.ruleClose.metricLimitCondition = JSON.stringify(this.incidentCloseJsonData)
      this.data.monitoringObjectParamExpression = JSON.stringify(this.monitoringCharacteristicsJson)

    },
    getCharacteristicsField() {
      HealthModelRuleApi.getCharacteristicsField().then((res) => {
        console.log(monitoringObjectsFields)
        console.log(monitoringObjectsFields.find(res => res.field === 'area'))

        this.propList = res[0].fields.map((des) => {
          des.dtoField = monitoringObjectsFields.find(res => res.field === des.dtoField).name
          return des;
        });
      })
    },
    selectProp(e,o) {
      console.log(e.toString())
      if(o.bdName == 'area_id' || o.bdName == 'type_id' || o.bdName == 'client_id') {
        HealthModelRuleApi.getPropertyPost({},o.url , 500).then((res) => {
          console.log(res)
          this.propValueList = res.data;
        })
      }
      else {
        HealthModelRuleApi.getProperty(o.url).then((res) => {
          console.log(res)
          this.propValueList = res;
        })
      }
      this.monitoringCharacteristicsJson.characteristics[o.index].propName = o.bdName;
      this.data.monitoringObjectParamExpression = JSON.stringify(this.monitoringCharacteristicsJson)
    },
    selectPropValue(e,option) {
      console.log(option)
      console.log( this.monitoringCharacteristicsJson.characteristics)
      console.log(   this.monitoringCharacteristicsJson.characteristics[option.index].propValueId)
      this.monitoringCharacteristicsJson.characteristics[option.index].propValueId.push(option.id)
      this.data.monitoringObjectParamExpression = JSON.stringify(this.monitoringCharacteristicsJson)
    },
    selectLimit(e,option){
      console.log(     this.data.metricLimits[option.index].limits[option.anotherIndex].name.id)
      console.log(option)
      this.data.metricLimits[option.index].limits[option.anotherIndex].name.id = option.id
      this.data.metricLimits[option.index].limits[option.anotherIndex].name.name = option.name
    },
    tests(e, option) {
      console.log(e)
      console.log(      this.monitoringCharacteristicsJson.characteristics[option.index].propValueId.findIndex(res => res === option.id))
      this.monitoringCharacteristicsJson.characteristics[option.index].propValueId.splice(this.monitoringCharacteristicsJson.characteristics[option.index].propValueId.findIndex(res => res === option.id), 1)
      this.data.monitoringObjectParamExpression = JSON.stringify(this.monitoringCharacteristicsJson)
    },
    jsonParse() {
      console.log(this.data)
      // this.incidentOpenJsonData = JSON.parse(this.data.ruleOpen.metricLimitCondition)
      // this.incidentCloseJsonData = JSON.parse(this.data.ruleClose.metricLimitCondition)
      this.monitoringCharacteristicsJson = JSON.parse(this.data.monitoringObjectParamExpression)
      let result
      result = this.characteristicsConditionList.find((res) => {
        return res.value === this.monitoringCharacteristicsJson.type
      })
      this.type = result.name;

    },
    supportTeamSelect(e,o) {
      this.data.ruleOpen.incidentTemplate.supportTeam = {
        id: o.id,
        name: o.value
      }
    },
    prioritySelect(e,o) {
      this.data.ruleOpen.incidentTemplate.priority = {
        id: o.id,
        name: o.value
      }
    },
    typeSelect(e,o) {
      this.data.ruleOpen.incidentTemplate.type = {
        id: o.id,
        name: o.value
      }
    },
    monitoringCharacteristicsTypeSelect(e,o) {
      this.monitoringCharacteristicsJson.type = o.id
      let result
      result = this.characteristicsConditionList.find((res) => {
        return res.value === o.id
      })
      console.log(result)
      console.log(this.characteristicsConditionList)
      // this.type = result.name;
      this.data.monitoringObjectParamExpression = JSON.stringify(this.monitoringCharacteristicsJson)
    },
    getLimitName() {
      HealthModelRuleApi.getLimitList().then((res) => {
        console.log('LIMIT?', res)
        this.limitNameList = res;
      })
    }
  },
  watch: {
    data: {
    handler() {
      if (this.data.monitoringObjectParamExpression
          && this.data.monitoringObjectParamExpression !== '{type: \'\', characteristics: []}'
          && this.data.id) {
        this.jsonParse()
      }
    },
      deep: true
    }
  }
})
