export const defineTextColorByAreaStatus = ({id}): string => {
  if(id === 1) {
    return 'text-green-1'
  }
  if(id == 3 || id == 6 || id == 5) {
    return 'text-red-7'
  }
  return 'text-orange-1'
};
export const getStatusStyle = (status) => {
  return status?.color ? `color: ${status.color}` : '';
}

export const getStatusClass = (status) => {
  if (!status?.color) {
    const className = defineTextColorByStatus(status);
    return className ? {[className]: true} : '';
  }
  return '';
}
export const defineTextColorByStatus = (status): string => {
  const id = status?.id;
  if (status?.color) {
    return status?.color;
  }
  if (!id) { return ''; }
  if (id === 1) {
    return 'text-green-1';
  }
  if (id === 3) {
    return 'text-red-7';
  }
  return 'text-orange-1';
}


export const defineTextColorByIncidentsStatus = (status): string => {
  const id = status?.id;
  if(!id) { return; }
  if(id === 5 || id === 4) {
    return 'text-green-1'
  }
  if(id === 1 || id === 6) {
    return 'text-red-7'
  }
  return 'text-orange-1'
}
