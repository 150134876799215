
import {AreaListData} from "@/types/area";
import {PropType, defineComponent} from "vue";
import ListBase from "@/classes/ListBase.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {MonitoringObjectsApi} from "@/api/monitoring-objects.api";

export default defineComponent({
  components: {DeleteDialog},
  mixins: [ListBase],
  props: {
    list: Object as PropType<AreaListData[]>,
    fields: {
      type: Object as PropType<AreaListData[]>,
      required: true,
    },
  },
  methods: {
    openDeleteDialog(equipmentId, equipmentName) {
      this.selectedEquipmentId = equipmentId;
      this.$refs.delete.openDialog("связи", `связь с объектом ${equipmentName}`);
    },
    deleteRelatedEquipment() {
      MonitoringObjectsApi.deleteMonitoringObjectService(this.selectedEquipmentId, this.serviceId).then((res) => {
        this.$emit('deleteEmit')
      })
    }
  }
});
