
import { defineComponent, reactive } from "vue";
import ServiceCharacteristics from "@/components/services/ServiceCharacteristics.vue";
import { ServiceApi } from "../../api/service.api";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import EditForm from "@/components/base/EditForm.vue";
import ViewBase from "@/classes/ViewBase.vue";
import MonitoringBase from "@/classes/MonitoringBase.vue";
import { ClickhouseApi } from "@/api/clickhouse";
import { Field } from "@/types/fields";
import MonitoringObjectsFilter from "@/components/monitoring-objects/MonitoringObjectsFilter.vue";
import MonitoringObjectsList from "@/components/monitoring-objects/MonitoringObjectsList.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import MetricList from "@/components/metrics/MetricList.vue";
import MetricIndicatorList from "@/components/metrics-indicators/MetricIndicatorList.vue";
import MetricsFilter from "@/components/metrics/MetricsFilter.vue";
import MetricsIndicatorsFilter from "@/components/metrics-indicators/MetricsIndicatorsFilter.vue";
import IncidentList from "@/components/incidents/IncidentList.vue";
import IncidentsFilter from "@/components/incidents/IncidentsFilter.vue";
import {CollectionAgentsApi} from "@/api/collection-agents.api";
import ArchiveDialog from "@/components/dialogs/ArchiveDialog.vue";
import {MetricGroupApi} from "@/api/metric-group.api";
import {MonitoringObjectsApi} from "@/api/monitoring-objects.api";
import {currentUser} from "@/consts/currentUser";

export default defineComponent({
  name: "ServiceView",
  components: {
    MetricsIndicatorsFilter,
    MetricsFilter,
    MetricIndicatorList,
    MetricList,
    MonitoringObjectsList,
    MonitoringObjectsFilter,
    ServiceCharacteristics,
    EditForm,
    ArchiveDialog,
    DeleteDialog,
    TableSettings,
    IncidentList,
    IncidentsFilter
  },
  mixins: [ViewBase, MonitoringBase],
  data: () => ({
    list: [],
    currentUser,
    body: null,
    selectorData: [
      { value: "Объекты", menuCode: 'monitoring', childMenuCode: 'object'},
      { value: "Типы метрик", menuCode: null, childMenuCode: null },
    ],
    currentSelect: "Объекты",
    visibleSidebarFilter: false,
    visibleSidebarTableSettings: false,
    type: "service",
    id: null,
    isShowMonitoring: false,

  }),
  mounted() {
    this.id = this.$route.params.id;
    this.sGroupKey = "serviceId";
    this.vGroupKey = this.id;
    this.getMonitoringObject();
    this.getById();
    this.getMetricGroup();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm(
      "Закрыть сайт? Возможно внесенные изменения не сохранятся!"
    );
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openArchiveDialog(){
      if (this.body.deleted) {
        this.$refs.archive.openDialog("Восстановление услуги",
            `восстановить из архива  услугу ${this.body.id}` , "Восстановить");
      }
      else {
        this.$refs.archive.openDialog("Архивирование услуги ",
            `архивировать  услугу ${this.body.id} `, "В архив");
      }
    },
    getMonitoringObject() {
      MonitoringObjectsApi.applyFilter({serviceId: this.id}, 100).then((res) => {
        if (res.totalCount == 0) {
          this.isShowMonitoring = false;
        }
        else {
          let sum = res.data.map(des => {
            return des?.type?.name;
          })
          this.isShowMonitoring = sum.includes('Услуга');
        }
      })
    },

    archiveObject() {
      if (this.body.deleted) {
        ServiceApi.unArchive(this.id).then(() => {
          this.getById();
          this.$notification.open({
            message: "Восстановлена из архива",
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px",
            },
            placement: 'bottomRight',
            duration: 3,
          });
        });
      }
      else {
        ServiceApi.archive(this.id).then(() => {
          this.getById();
          this.$notification.open({
            message: "Архивирована",
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px",
            },
            placement: 'bottomRight',
            duration: 3,
          });
        });
      }
    },
    getMetricGroup() {
      ServiceApi.getMetrics(this.$route.params.id).then((res) => {
        this.metrics = this.sourceMetrics = res.monitoringObjects
        this.setDefaultMetrics();
      });
    },
    findDefaultMetric() {
      const defaultMetric = this.metrics.reduce(
        (prev, current) =>
          prev.concat(current.metrics).concat(current.indicators),
        []
      ).find((metric) => metric?.name === "Статус канала связи");
      if(!defaultMetric) {
        return;
      }
      this.selectedMetrics.push(defaultMetric);
    },
    getMonitoringData({ id, name, instanceName,monitoringObjectId  }) {
      this.isLoadingGraphic = true;
      if (instanceName) {
        ClickhouseApi.getIndicatorData(
          instanceName,
          "day",
          this.period[0].format("DD.MM.yyyy HH:mm:ss"),
          this.period[1].format("DD.MM.yyyy HH:mm:ss"),
          ""
        ).then((res) => this.computeMonitoringData({ res, id, name }));
        return;
      }
      ClickhouseApi.getMonitoringData(
        id,
        "day",
        this.period[0].format("DD.MM.yyyy HH:mm:ss"),
        this.period[1].format("DD.MM.yyyy HH:mm:ss"),
          monitoringObjectId.toString()
      ).then((res) => this.computeMonitoringData({ res, id, name }));
    },
    handleDeleteRelatedObject(id) {
      if (this.currentSelect === 'Объекты') {
        this.metrics = []
        this.getById();
        this.getMetricGroup();
        this.getMonitoringObject();
      }
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog("услуги", `услугу ${this.$route.params.id}`);
    },
    getById() {
      if (this.$refs.characteristics) this.$refs.characteristics.clean();

      ServiceApi.getById(this.$route.params.id)
        .then((res) => {
          this.body = res;
        })
        .finally(() => {
          this.$refs.characteristics.cleanWarning();
        });
    },
    save() {
      if (this.$refs.characteristics.validate()) {
        return;
      }
      const characteristic =
        this.$refs.characteristics.formatCharacteristicsCopy();
      ServiceApi.save(characteristic).then((res) => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: "bottomRight",
          duration: 3,
        });
        this.getById();
      });
    },
    routeToRelatedEquipments() {
      this.$router.push({
        path: `/monitoring-object/`,
        query: {
          isRelated: `true`, relatedId: this.$route.params.id,
          relatedName: this.body.orderN, relatedTitle: 'для услуги'
        }
      });
    },
    deleteObject() {
      ServiceApi.delete(this.$route.params.id).then((res) => {
        this.$router.go(-1);
      });
    },
  },
});
