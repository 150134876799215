
import { defineComponent } from "vue";
import {ExecutorUsersApi} from "../../api/executor-users.api";
import ExecutorUsersCharacteristics from "@/components/executor-users/ExecutorUsersCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import ViewBase from "@/classes/ViewBase.vue";
import RolesList from "@/components/roles/RolesList.vue";
import RolesFilter from "@/components/roles/RolesFilter.vue";
import GroupsList from "@/components/groups/GroupsList.vue";
import GroupsFilter from "@/components/groups/GroupsFilter.vue";
import SupportTeamList from "@/components/support-teams/SupportTeamList.vue";
import SupportTeamsFilter from "@/components/support-teams/SupportTeamsFilter.vue";
import ChangePassDialog from "@/components/dialogs/ChangePassDialog.vue";
import {suffix} from "@/consts/nameSuff";

export default defineComponent({
  name: "ExecutorUsersView",
  components: { ExecutorUsersCharacteristics, EditForm, DeleteDialog, ChangePassDialog,
    RolesList, RolesFilter, GroupsList, GroupsFilter, TableSettings, SupportTeamList, SupportTeamsFilter },
  mixins: [ViewBase],
  data: () => ({
    body: {},
    list: [],
    selectorData: [
      {value: 'Роли', menuCode: 'security', childMenuCode: 'roles' },
      {value: 'Группы', menuCode: 'security', childMenuCode: 'groups' }
    ],
    currentSelect: 'Роли',
    type: 'executor-users',
    visibleSidebarFilter: false,
    visibleSidebarTableSettings: false,
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.sKey = 'userId';
    this.vKey = +this.id;
    this.getById();


    const accessParams = { module: 'security', object: 'users' };
    const tabMap = {
      'tab_common_information_users': '1',
      'tab_relation_users': '3',
    };
    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("пользователя", `пользователя ${this.body.fio}`);
    },
    openChangePassDialog() {
      this.$refs.changepass.openDialog("пользователя", `пользователя ${this.body.fio}`);
    },
    getById() {
      ExecutorUsersApi.getById(this.id).then((res) => {
        suffix.next(' ' + res.login);
        if (res.contacts)
          res.contacts.sort((a, b) => b.contactType.id - a.contactType.id);
        this.body = res;
        if (res.userType.id === 3) {
          this.body.organization = null;
          this.$refs.characteristics.validationFields =
              this.$refs.characteristics.validationFields.filter(field => field.field !== 'organization.name');
        }
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const contacts = this.$refs.characteristics.data.contacts;
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      // 1, 3 clientID, 2 - executorID
      if(characteristics.userType.id == 2) {
        characteristics.executorId = characteristics.organization?.id;
        delete characteristics.clientId;
      } else {
        characteristics.clientId =
            characteristics.userType.id == 1 ? characteristics.organization?.id : 1;
        delete characteristics.executorId;
      }
      characteristics.userTypeId = characteristics.userType.id;
      delete characteristics.userType;

      let contractsInfo = [];
      Object.keys(contacts).forEach(key => {
        contractsInfo.push(contacts[key]);
      });
      characteristics.contacts = contractsInfo;

      ExecutorUsersApi.save(
          characteristics
      ).then(() => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.getById();
      });
    },
    deleteObject() {
      ExecutorUsersApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
    changePassword(e) {
      let pass = btoa(e)
      ExecutorUsersApi.changePassword(this.body.keycloakId,pass, {}).then(() => {
        this.$notification.open({
          message: "Пароль успешно изменен",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
      })
    },
    sendMessage() {
       ExecutorUsersApi.sendMessageToChangePassword(this.body.keycloakId, {}).then(() => {
         this.$notification.open({
           message: "Сообщение о смене пароля отправлено",
           style: {
             width: "330px",
             marginLeft: `${400 - 330}px`,
             fontSize: "14px",
           },
           placement: 'bottomRight',
           duration: 3,
         });
       })
    }
  },
});
