
import {Options, Vue} from "vue-class-component";
import NSIBase from "@/classes/NSIBase.vue";
import {currentUser} from "@/consts/currentUser";

@Options({
  components: {},
  mixins: [NSIBase],
  data: () => {
    return {
      menuData: [
        {
          title: 'Заявки',
          childTitle: [
            {
              title: 'Типы заявок',
              url: '/directory/incident-type',
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'types_of_applications'
            },
            {
              title: 'Зона ответственности',
              url: '/directory/responsibility',
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'areas_of_responsibility'
            },
            {
              title: 'Команды поддержки',
              url: '/directory/support-teams',
              withoutAccess: ['nav_contracts'],
              menuCode: 'support_teams'
            },
            {
              title: 'Контрагенты',
              url: '/directory/counterparty',
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'contractors'
            },
            {
              title: 'Договоры',
              url: '/directory/contracts',
              menuCode: 'contracts'
            },
            {
              title: 'Срочность',
              url: '/directory/urgency',
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'urgency'
            },
            {
              title: 'Служебные атрибуты',
              url: '/directory/custom-attributes',
              withoutAccess: ['nav_contracts', 'support_engineer_internal'],
              menuCode: 'service_attributes'
            },
          ],
        },
        {
          title: 'Метрики',
          childTitle: [
            {
              title: 'Типы метрик',
              url: '/directory/metrics-type',
              menuCode: 'types_of_metrics'
            },
            {
              title: 'Лимит метрик',
              url: '/directory/metric-limits',
              menuCode: 'metric_limit'
            },
            {
              title: 'Группы метрик',
              url: '/directory/metrics-groups',
              menuCode: 'groups_of_metrics'
            },
            {
              title: 'Категории метрик',
              url: '/directory/metric-tag',
              menuCode: 'categories_of_metrics'
            },
            {
              title: 'Единицы измерения',
              url: '/directory/measure',
              menuCode: 'units_of_measurement'
            }
          ],
          withoutAccess: ['nav_contracts', 'support_engineer_internal']
        }, {
          title: 'Услуги',
          childTitle: [
            {
              title: 'Типы услуги',
              url: '/directory/channel-types',
              menuCode:'types_of_services'
            },
          ],
          withoutAccess: ['nav_contracts', 'support_engineer_internal']
        }, {
          title: 'Местоположения',
          childTitle: [
            {
              title: 'Местоположения',
              url: '/directory/areas',
              menuCode: 'locations'
            },
            {
              title: 'Типы местоположений',
              url: '/directory/area-types',
              withoutAccess: ['support_engineer_internal'],
              menuCode: 'types_of_locations'
            },
            {
              title: 'Этапы внедрения',
              url: '/directory/operation-queue',
              withoutAccess: ['support_engineer_internal'],
              menuCode: 'stages_of_implementation'
            },
            {
              title: 'Подразделения',
              url: '/directory/departments',
              withoutAccess: ['support_engineer_internal'],
              menuCode: 'divisions'
            }
          ],
          withoutAccess: ['nav_contracts']
        }, {
          title: 'Шаблоны',
          childTitle: [
            {
              title: 'Шаблоны уведомлений',
              url: '/directory/notice-template',
              menuCode: 'notification_template'
            },
            {
              title: 'Шаблоны моделей здоровья',
              url: '/directory/health-model-rule',
              menuCode: 'template_for_health_models'
            }
          ],
          withoutAccess: ['nav_contracts','support_engineer_internal']
        }, {
          title: 'Оборудование',
          childTitle: [
            {
              title: 'Производители',
              url: '/directory/brands',
              menuCode: 'manufacturers'
            },
            {
              title: 'Модели',
              url: '/directory/models',
              menuCode: 'model'
            },
            {
              title: 'Типы объектов',
              url: '/directory/equipment-types',
              withoutAccess: ['support_engineer_internal'],
              menuCode: 'types_of_objects'
            }
          ],
          withoutAccess: ['nav_contracts']
        },
      ]
    }
  },
  methods: {
    routeToUrl(url) {
      this.$router.push(url);
    },
    hasAccessToRead(cell) {
      let result = false
      let childItemList = currentUser.value.access.items.find(res => res.menuCode === 'dictionary').children
      result = childItemList.find(res => res.menuCode === cell.menuCode) ? currentUser.value.access.items.find(res => res.menuCode === 'dictionary').children.find(res => res.menuCode === cell.menuCode).read : false
      return result ? result : false
    },
    hasAnyActiveChapter(cell) {
      let result = false
      let resultMass = []
      let childItemList = currentUser.value.access.items.find(res => res.menuCode === 'dictionary').children
      for ( let item of cell.childTitle) {
        for (let childItem of childItemList) {
          if (childItem.menuCode === item.menuCode) {
            resultMass.push(childItem.read)
          }
        }
      }
      return resultMass.includes(true)
    }
  }
})
export default class DirectoryMenu extends Vue {

}
