
import {defineComponent} from "vue";
import EditForm from "@/components/base/EditForm.vue";
import {RolesApi} from "@/api/roles.api";
import GroupCharacteristics from "@/components/groups/GroupCharacteristics.vue";
import RoleCharacteristics from "@/components/roles/RoleCharacteristics.vue";
export default defineComponent({
  name: "RoleAdd",
  components: {RoleCharacteristics , EditForm },
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      console.log(this.$refs.characteristics)
      if(this.$refs.characteristics.validate()) {
        return;
      }
      console.log(this.$refs.characteristics)
      // const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      const characteristics = this.$refs.characteristics.data;
      characteristics.menuItems = this.$refs.characteristics.menuItemsList
      RolesApi.create(
          characteristics
      ).then((res) => {
        this.route(res);
      });
    },
    route(id) {
      this.$refs.characteristics.cleanWarning();
      this.$router.replace("/security/roles/" + id);
    },
  },
});
