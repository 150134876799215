
import { IncidentsApi } from "@/api/incidents.api";
import { AreaApi } from "@/api/area.api";
import { Util } from "@/components/utils/Util";
import moment from "moment";
import {mixins, Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import FilterBase from "@/classes/FilterBase.vue";
import Super from "@/classes/FilterBase.vue";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import {Field} from "@/types/fields";
import {AreaFilterImp} from "@/components/classes/AreaFilterImp";

@Options({
  components: {
    Accordion,
  },
  mixins: [FilterBase],
  props: {
    fields: [] as Field<unknown>[],
  },
  watch: {
    fields: {
      handler(val) {
        this.setFilterFields();
        this.applyFilter('filter');
      },
      deep: true
    }
  },
  data: () => ({
    isVisible: false,
    filter: new AreaFilterImp(),
    areas: [],
    divisionName: '',
    regionCode: '',
    districtName: '',
    cityName: '',
    streetName: '',
    valuesForCheckboxes: {
      divisions: [],
      divisionsArr: [],
      stagesArr: [],
      stages: [],
      areaTypes: [],
      areaTypesArr: [],
      regionCodes: [],
      regionCodesArr: [],
      // districts: [],
      // districtsArr: [],
      cities: [],
      citiesArr: [],
      streets: [],
      streetsArr: [],
      // clients: [],
      // clientsArr: [1],
      statusesArr: [],
      activity: [
        {value: '', label: 'Неважно'},
        {value: 'true', label: 'Да'},
        {value: 'false', label: 'Нет'},
      ],
      activityArr: [''],
      archive: [
        {value: '', label: 'Неважно'},
        {value: 'true', label: 'Да'},
        {value: 'false', label: 'Нет'},
      ],
      deleted: [''],
    },
    filterData: {}
  }),
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    handleVisibleChange(e1) {
      console.log(e1);
    },
    getDivisions(divisionName?) {
      const name = divisionName || this.divisionName;
      AreaApi.getDivisions(name)
          .then(res => {
            this.valuesForCheckboxes.divisions = Util.transformToCheckboxes(res);
          });
    },
    getRegionCodes(regionCodeName?) {
      const arrChecked = [];
      if (this.valuesForCheckboxes.regionCodesArr.length > 0) {
        this.valuesForCheckboxes.regionCodesArr.forEach(id => {
          const checked = this.valuesForCheckboxes.regionCodes.filter(mg => mg.id === id);
          if (checked.length > 0) {
            arrChecked.push(checked[0]);
          }
        });
      }
      const name = regionCodeName || this.regionCode;
      AreaApi.getRegionsList(name)
          .then(res => {
            this.valuesForCheckboxes.regionCodes = Util.transformToCheckboxes(res);
            if(arrChecked.length > 0) {
              arrChecked.forEach(c => {
                if (!this.valuesForCheckboxes.regionCodes.some(mg => mg.id === c.id)) {
                  this.valuesForCheckboxes.regionCodes.push(c);
                }
              });
            }
          });
    },
    // getDistricts(districtName?) {
    //   const name = districtName || this.districtName;
    //   AreaApi.getDistrictsAsText(name, 0, 50)
    //       .then(res => {
    //         this.valuesForCheckboxes.districts = Util.transformToCheckboxesString(res);
    //       });
    // },
    getCities(cityName?) {
      const arrChecked = [];
      if (this.valuesForCheckboxes.citiesArr.length > 0) {
        this.valuesForCheckboxes.citiesArr.forEach(value => {
          const checked = this.valuesForCheckboxes.cities.filter(mg => mg.value === value);
          if (checked.length > 0) {
            arrChecked.push(checked[0]);
          }
        });
      }
      const name = cityName || this.cityName;
      AreaApi.getCitiesAsText(name, 0, 50)
          .then(res => {
            this.valuesForCheckboxes.cities = Util.transformToCheckboxesStringVal(res);
            if(arrChecked.length > 0) {
              arrChecked.forEach(c => {
                if (!this.valuesForCheckboxes.cities.some(mg => mg.value === c.value)) {
                  this.valuesForCheckboxes.cities.push(c);
                }
              });
            }
          });
    },
    getStreets(streetName?) {
      const name = streetName || this.streetName;
      AreaApi.getStreetsAsText(name, 0, 50)
          .then(res => {
            this.valuesForCheckboxes.streets = Util.transformToCheckboxesString(res);
          });
    },
    getStatus() {
      AreaApi.getStatus().then((res) => {
        this.valuesForCheckboxes.statuses = Util.transformToCheckboxes(res);
      });
    },
    onCheckLastValue(array, name) {
      Util.onCheckLastValue(array, name);
    },
    onCheckManyValues(array, name) {
      Util.onCheckManyValues(array, name);
    },
    applyFilter() {
      this.filter.divisions = (this.valuesForCheckboxes.divisionsArr.length === 1
          && this.valuesForCheckboxes.divisionsArr[0] === '')
          ? [] : this.valuesForCheckboxes.divisionsArr;
      this.filter.areaTypes = (this.valuesForCheckboxes.areaTypesArr.length === 1
          && this.valuesForCheckboxes.areaTypesArr[0] === '')
          ? [] : this.valuesForCheckboxes.areaTypesArr;
      this.filter.stages = (this.valuesForCheckboxes.stagesArr.length === 1
          && this.valuesForCheckboxes.stagesArr[0] === '')
          ? [] : this.valuesForCheckboxes.stagesArr;
      this.filter.areaStatuses = (this.valuesForCheckboxes.statusesArr.length === 1
              && this.valuesForCheckboxes.statusesArr[0] === '')
              ? [] : this.valuesForCheckboxes.statusesArr;
      this.filter.regionCodes = (this.valuesForCheckboxes.regionCodesArr.length === 1
          && this.valuesForCheckboxes.regionCodesArr[0] === '')
          ? [] : this.valuesForCheckboxes.regionCodesArr;
      // this.filter.districtNames = (this.valuesForCheckboxes.districtsArr.length === 1
      //     && this.valuesForCheckboxes.districtsArr[0] === '')
      //     ? [] : this.valuesForCheckboxes.districtsArr;
      this.filter.cityNames = (this.valuesForCheckboxes.citiesArr.length === 1
          && this.valuesForCheckboxes.citiesArr[0] === '')
          ? [] : this.valuesForCheckboxes.citiesArr;
      this.filter.streetNames = (this.valuesForCheckboxes.streetsArr.length === 1
          && this.valuesForCheckboxes.streetsArr[0] === '')
          ? [] : this.valuesForCheckboxes.streetsArr;
      this.filter.activity = this.valuesForCheckboxes.activityArr[0] === ''
          ? null : this.valuesForCheckboxes.activityArr[0] === 'true';
      // this.filter.clientId = this.valuesForCheckboxes.clientsArr;
      this.filter.deleted = this.valuesForCheckboxes.deleted[0] === ''
          ? null : this.valuesForCheckboxes.deleted[0] === 'true';
      this.filterChange(new AreaFilterImp());
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      let queryParams: any = {};
      this.setQueryFromFilterParam(queryParams, ['name', 'id', 'containsValue']);
      this.setQueryParamsArrDefault(queryParams, ['activityArr', 'citiesArr', 'statusesArr']);
      if(this.valuesForCheckboxes.activityArr[0] !== '') {
        queryParams.activity = this.valuesForCheckboxes.activityArr[0] === 'true';
      }

      this.$router.push({query: queryParams});
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['name', 'id', 'containsValue']);
      this.setCheckboxesArrFromQuery(query, ['divisions', 'areaTypes', 'stages', 'regionCodes', 'streets']);
      if(query.activity) {
        this.valuesForCheckboxes.activityArr[0] = query.activity;
      }
      if(query.areaTypes) {
        this.valuesForCheckboxes.areaTypesArr[0]
            = this.setCheckboxesArrFromQueryString(query, ['areaTypes']);
      }
    },
    cleanFilter() {
      this.filter = new AreaFilterImp();
      this.defaultValueFilter();
      this.$router.push({query: {}});
      this.setFilterFields();
      this.filterChange(new AreaFilterImp());
      this.$emit("filter", this.filter);
    },
    defaultValueFilter() {
      this.defaultValueCheckboxes([]);
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            if (f.field === 'id') {
              this.filter.id = '';
            }
            if (f.field === 'code') {
              this.filter.containsValue = '';
            }
            if (f.field === 'name') {
              this.filter.name = '';
            }
            if (f.field === 'division') {
              this.valuesForCheckboxes.divisionsArr = [''];
            }
            if (f.field === 'region') {
              this.valuesForCheckboxes.regionCodesArr = [''];
            }
            if (f.field === 'city') {
              this.valuesForCheckboxes.citiesArr = [''];
            }
            if (f.field === 'street') {
              this.valuesForCheckboxes.streetsArr = [''];
            }
            if (f.field === 'active') {
              this.valuesForCheckboxes.activityArr = [''];
            }
            if (f.field === 'areaStage') {
              this.valuesForCheckboxes.stagesArr = [''];
            }
            if(f.field === 'areaStatus') {
              this.valuesForCheckboxes.statusesArr = [''];
            }
          });
    },
    getAreaTypeList() {
      AreaApi
          .getAreaType()
          .then((res) => {
            this.valuesForCheckboxes.areaTypes = Util.transformToCheckboxesStringVal(res.data);
          });
    },
    getStages() {
      AreaApi
          .getStages()
          .then((res) => {
            this.valuesForCheckboxes.stages = Util.transformToCheckboxes(res);
          });
    },
  },
  mounted() {
    // Promise.all([this.getAreaTypeList(), this.getStages(),
    //   this.getDivisions(), this.getRegionCodes(), this.getDistricts(), this.getCities(), this.getStreets()])
    Promise.all([this.getAreaTypeList(), this.getStages(), this.getStatus(),
      this.getDivisions(), this.getRegionCodes()])
        .then(() => {
          this.defaultValueFilter();
          this.getQueryParams();
          this.setFilterFields();
          this.applyFilter();
        })
  },
  setup() {
    return {
      locale
    }
  },
})

export default class AreasFilter extends Vue {
}
