
import {defineComponent} from "vue";
import ListBase from "@/classes/ListBase.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {HealthModelRuleApi} from "@/api/health-model-rule.api";

export default defineComponent ({
  mixins: [ListBase],
  components: {DeleteDialog},
  methods: {
    selectRelate(e, id) {
      console.log(e)
      if (e.target.checked) {
        this.$emit('checked',id)
      }
      else {
        this.$emit('unChecked',id)
      }
    },
    openDeleteDialog(id, name) {
      this.selectedId = id;
      this.$refs.delete.openDialog("связи", `связь с правилом ${name}`);
    },
    deleteObjectEmit() {
      HealthModelRuleApi.deleteRule(this.selectedId).then(() => {
        this.onChange(this.currentPage, this.currentSize);
      });
    }
  },
  props: {
    isRefresh: {default: false}
  },
  data: () => ({
    checked: [true,true,true,true,true]
  }),
  emits: ["unChecked", "checked"],
})
