
    import {defineComponent, ref} from 'vue';

    export default defineComponent({
        components: {},
        data() {
            return {
                title: 'Импорт файла',
                form: {
                    file: null
                },
            }
        },
        setup() {
            const visible = ref <boolean> (false);

            const showModal = () => {
                visible.value = true;
            };

            const handleOk = (e: MouseEvent) => {
                visible.value = false;
            };

            const handleCancel = () => {
                visible.value = false;
            };

            return {
                visible,
                showModal,
                handleOk,
                handleCancel
            };
        },
        emits: ["update-dialog"],
        methods: {
            openDialog(title?) {
              if(title) {
                this.title = title;
              }
              this.form.file = null;
              this.showModal();
            },
            handleChange(files) {
              console.log('handleChange', files);
              if(files.fileList.length > 1) {
                files.fileList.shift();
              }
              files.fileList = this.form.file = files.fileList.map(_ => {_.status = 'done'; return _})
            },
            addFile() {
              console.log("!!! addFile");
              this.$emit('update-dialog', this.form.file[0]);
              this.handleCancel();
            },
            //   this.form.file.forEach(({name, originFileObj}) => {
            //       formData.append('file', originFileObj, name);
            //   });
            customRequest(ev) {
                console.log(ev);
            },
        }
    });
