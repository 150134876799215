
import {defineComponent} from "vue";
import {ExecutorUsersApi} from "@/api/executor-users.api";
import ExecutorUsersCharacteristics from "@/components/executor-users/ExecutorUsersCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";

export default defineComponent({
  name: "ExecutorUsersAdd",
  components: { ExecutorUsersCharacteristics, EditForm },
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const contacts = this.$refs.characteristics.data.contacts;
      const characteristics = this.$refs.characteristics.formatCharacteristics();
      // 1, 3 clientID, 2 - executorID
      const userType = characteristics.userType;
      if(characteristics.userType.id == 2) {
        characteristics.executorId = characteristics.userType.id;
      } else {
        characteristics.clientId =
            characteristics.userType.id == 1 ? characteristics.organization?.id : 1;
      }
      characteristics.userTypeId = characteristics.userType.id;
      delete characteristics.userType;

      let contractsInfo = [];
      Object.keys(contacts).forEach(key => {
        contractsInfo.push(contacts[key]);
      });
      characteristics.contacts = contractsInfo;

      ExecutorUsersApi.save(
          characteristics
      ).then((res) => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.route(res.id);
      }).catch((_) => {
        characteristics.contacts = contacts;
        characteristics.userType = userType;
        characteristics.executorId = null;
        characteristics.clientId = null;
        characteristics.userTypeId = null;
      });
    },
    route(id?) {
      this.$refs.characteristics.cleanWarning();
      if (!id) {
        this.$router.replace("/security/executor-users/");
        return;
      }
      this.$router.replace("/security/executor-users/" + id);
    },
  },
});
