
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getHealthModelRuleData} from "@/consts/fields/health-model-rule.fields";
import {ClassifilesApi} from "@/api/classifires.api";
import {MetricApi} from "@/api/metric.api";
import {HealthModelRuleApi} from "@/api/health-model-rule.api";
import {monitoringObjectsFields} from "@/consts/fields/monitoring-objects.fields";
import {getHealthModelRulesData} from "@/consts/fields/health-model-rules.fields";
import {NoticeTemplateApi} from "@/api/notice-template.api";

export default defineComponent ({
  name: "HealthModelRulesCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    isLoad: false,
    data: getHealthModelRulesData(),
    validationFields:[
      {field: "name", validators: ["required"]},
      {field: "openType", validators: ["required"]},
      {field: "closeType", validators: ["required"]},
    ],
    expressionApplianceTypeList: [{name: 'Времени', value: 'TIME'}, {name: 'Последнему значению', value: 'LAST_VALUE'}],
    characteristicsConditionList: [{name: 'И', value: 'AND'}, {name: 'ИЛИ', value: 'OR'}, {name:'Исключающее ИЛИ', value:'XOR'}],
    priorityList: [],
    incidentTypeList: [],
    supportTimeList: [],
    noticeTemplateList: [],
    metricList: [],
    testData: {
      checkOpen: 'Времени',
      checkClose: 'Времени',
      expressionOpen: [
        {id: 1, metricId: 12, metricName: 'Доступность', condition: '>', limit: 'Порог1'},
        {id: 2, metricId: 10, metricName: 'MTU', condition: '<', limit: 'Порог3'}
      ],
      expressionClose: [
        {id: 1, metricId: 12, metricName: 'Доступность', condition: '<', limit: 'Порог1'},
        {id: 2, metricId: 10, metricName: 'MTU', condition: '>', limit: 'Порог3'}
      ]
    },
    conditionList: [
      {value: '>'},
      {value: '<'},
      {value: '>='},
      {value: '<='},
    ],
    actionList: [
      {value: "Создание заявки"}
    ],
    action: "",
    incidentOpenJsonData: {expression: []},
    incidentCloseJsonData: {expression: []},
    jsonActionParamsList: [],
    monitoringCharacteristicsJson: {
      type: '',
      characteristics: []
    },
    propList: [],
    propValueList: []
  }),

  mounted() {
    this.getIncidentFilterList();
    this.getCharacteristicsField();
    if(this.$route.query.relatedId) {
      console.log(this.$route.query.relatedId)
      this.data.healthModelTemplateId = this.$route.query.relatedId
    }
    this.getRuleActionTypeList();
    this.getNoticeTemplateList();
  },
  methods: {
    getNoticeTemplateList(){
      NoticeTemplateApi.applyFilter({}).then((res) => {
        console.log(res)
        this.noticeTemplateList = res.data;
      })
    },
    getIncidentFilterList() {
      ClassifilesApi.getIncidentFilterList().then((res)=> {
        this.priorityList = res.data.priority
        this.incidentTypeList = res.data.types
        this.supportTimeList = res.data.supportTeams
      })
    },
    getMetricList(name?) {
      HealthModelRuleApi.getMetricForRule(this.data.healthModelTemplateId).then((res)=> {
        this.metricList = res;
      })
    },
    getRuleActionTypeList(name?) {
      HealthModelRuleApi.getRuleActionType().then((res)=> {
        this.actionList = res;
      })
    },

    addMetricLimit() {
      this.data.metricLimits.push({
        id: this.data.metricLimits.length + 1,
        metricId: null,
        metricLimit1Name: "Порог1",
        metricLimit1Value: null,
        metricLimit2Name: "Порог2",
        metricLimit2Value: null,
        metricLimit3Name: "Порог3",
        metricLimit3Value: null,
        metricName: ""
      })
    },
    deleteMetricLimit(index) {
      this.data.metricLimits.splice(index,1)
    },
    addRuleOpenExpression() {
      this.incidentOpenJsonData.expression.push( {
        id:   this.incidentOpenJsonData.expression.length + 1,
        metricId: null,
        metricName: "",
        condition: "",
        limit: ""
      })
      this.data.metricLimitCondition = JSON.stringify(this.incidentOpenJsonData)
    },
    addMonitoringParams() {
      this.monitoringCharacteristicsJson.characteristics.push( {
        propName: "",
        propValueName: [],
        propValueId: []
      })
      this.data.monitoringObjectParamExpression= JSON.stringify(this.monitoringCharacteristicsJson)
    },
    deleteMonitoringParams(index) {
      this.monitoringCharacteristicsJson.characteristics.splice(index,1)
      this.data.monitoringObjectParamExpression= JSON.stringify(this.monitoringCharacteristicsJson)
    },
    deleteOpenExpression(index) {
      this.incidentOpenJsonData.expression.splice(index,1)
      this.data.metricLimitCondition = JSON.stringify(this.incidentOpenJsonData)
    },
    addRuleCloseExpression() {
      this.incidentCloseJsonData.expression.push( {
        id:   this.incidentCloseJsonData.expression.length + 1,
        metricId: null,
        metricName: "",
        condition: "",
        limit: ""
      })
      this.data.metricLimitConditionClose = JSON.stringify(this.incidentCloseJsonData)
    },
    addActionRules(){
      this.data.actions.push({
        healthModelActionType: {
          name: "Создание заявки",
          id: 1
        },
        id: null,
        jsonActionParams: '[{"field":"priority","paramsName":"","paramsId":""},{"field":"type","paramsName":"","paramsId":""},{"field":"supportTeam","paramsName":"","paramsId":""}]'
      })
      this.jsonActionParamsList.push([{field:"priority",paramsName:"",paramsId:""},{field:"type",paramsName:"",paramsId:""},{field:"supportTeam",paramsName:"",paramsId:""}])
    },
    deleteActionRules(index){
      this.data.actions.splice(index,1)
      this.jsonActionParamsList.splice(index,1)
    },
    deleteCloseExpression(index) {
      this.incidentCloseJsonData.expression.splice(index,1)
      this.data.metricLimitConditionClose = JSON.stringify(this.incidentCloseJsonData)
    },
    selectMetric(e,option) {
      this.data.metricLimits[option.index].metricId = option.id
    },
    selectOpenMetric(e, option) {
      this.incidentOpenJsonData.expression[option.index].metricId = option.id
      this.data.metricLimitCondition = JSON.stringify(this.incidentOpenJsonData)

    },
    selectCloseMetric(e, option) {
      this.incidentCloseJsonData.expression[option.index].metricId = option.id
      this.data.metricLimitConditionClose = JSON.stringify(this.incidentCloseJsonData)
    },
    approveSelect() {
      this.data.metricLimitCondition = JSON.stringify(this.incidentOpenJsonData)
      this.data.metricLimitConditionClose = JSON.stringify(this.incidentCloseJsonData)

    },
    getCharacteristicsField() {
      HealthModelRuleApi.getCharacteristicsField().then((res) => {

        this.propList = res[0].fields.map((des) => {
          des.dtoField = monitoringObjectsFields.find(res => res.field === des.dtoField).name
          return des;
        });
      })
    },
    selectProp(e,o) {
      if(o.id == 918 || o.id == 925 || o.id == 917) {
        HealthModelRuleApi.getPropertyPost({},o.url , 500).then((res) => {
          this.propValueList = res.data;
        })
      }
      else {
        HealthModelRuleApi.getProperty(o.url).then((res) => {
          this.propValueList = res;
        })
      }
      this.data.monitoringObjectParamExpression = JSON.stringify(this.monitoringCharacteristicsJson)
    },
    selectPropValue(e,option) {
      this.monitoringCharacteristicsJson.characteristics[option.index].propValueId.push(option.id)
      this.data.monitoringObjectParamExpression = JSON.stringify(this.monitoringCharacteristicsJson)
    },
    tests(e, option) {
      this.monitoringCharacteristicsJson.characteristics[option.index].propValueId.splice(this.monitoringCharacteristicsJson.characteristics[option.index].propValueId.findIndex(res => res === option.id), 1)
      this.data.monitoringObjectParamExpression = JSON.stringify(this.monitoringCharacteristicsJson)
    },
    jsonParse() {
      console.log(this.data)
      this.incidentOpenJsonData = JSON.parse(this.data.metricLimitCondition);
      this.incidentCloseJsonData = JSON.parse(this.data.metricLimitConditionClose);
      this.data.openType = this.incidentOpenJsonData.type
      this.data.closeType = this.incidentCloseJsonData.type
     for (let i = 0; i < this.data.actions.length;i++) {
       console.log(this.data.actions[i].jsonActionParams)
       this.jsonActionParamsList.push(JSON.parse(this.data.actions[i].jsonActionParams))
     }
     console.log(this.jsonActionParamsList)
    },
    noticeTemplateSelect(e,o) {
      this.jsonActionParamsList[o.index][1].paramsName = o.value
      this.jsonActionParamsList[o.index][1].paramsId = o.id.toString()
      this.data.actions[o.index].jsonActionParams = JSON.stringify(this.jsonActionParamsList[o.index])
    },
    supportTeamSelect(e,o) {
      this.jsonActionParamsList[o.index][2].paramsName = o.value
      this.jsonActionParamsList[o.index][2].paramsId = o.id.toString()
      this.data.actions[o.index].jsonActionParams = JSON.stringify(this.jsonActionParamsList[o.index])
    },
    prioritySelect(e,o) {
      this.jsonActionParamsList[o.index][0].paramsName = o.value
      this.jsonActionParamsList[o.index][0].paramsId = o.id.toString()
      this.data.actions[o.index].jsonActionParams = JSON.stringify(this.jsonActionParamsList[o.index])
    },
    typeSelect(e,o) {
      this.jsonActionParamsList[o.index][1].paramsName = o.value
      this.jsonActionParamsList[o.index][1].paramsId = o.id.toString()
      this.data.actions[o.index].jsonActionParams = JSON.stringify(this.jsonActionParamsList[o.index])
    },
    incidentOpenTypeSelect(e,o) {
      this.incidentOpenJsonData.type = o.value
      this.data.openType = o.value
      this.data.metricLimitCondition = JSON.stringify(this.incidentOpenJsonData)
    },
    incidentCloseTypeSelect(e,o) {
      this.incidentCloseJsonData.type = o.value
      this.data.closeType = o.value
      this.data.metricLimitConditionClose = JSON.stringify(this.incidentCloseJsonData)
    },
    actionTypeSelect(e,o) {
      this.data.actions[o.index].healthModelActionType.id = o.id
      if (o.id == 1) {
        this.data.actions[o.index].jsonActionParams = '[{"field":"priority","paramsName":"","paramsId":""},{"field":"type","paramsName":"","paramsId":""},{"field":"supportTeam","paramsName":"","paramsId":""}]'
        this.jsonActionParamsList[o.index] = [{field:"priority",paramsName:"",paramsId:""},{field:"type",paramsName:"",paramsId:""},{field:"supportTeam",paramsName:"",paramsId:""}]
      }
      else {
        this.data.actions[o.index].jsonActionParams = '[{"field":"emailAddress","address":""},{"field":"noticeTemplate","paramsName":"","paramsId":""}]'
        this.jsonActionParamsList[o.index] =  [{field:"emailAddress",address:""},{field:"noticeTemplate",paramsName:"",paramsId:""}]
      }
    },
    addressInputChange(index){
      this.data.actions[index].jsonActionParams = JSON.stringify( this.jsonActionParamsList[index])
    },
    customValidation(field = "", event?, validationField?) {
      if(field !== 'connectSpeed' && field !== "measure.name") {
        return false;
      }
      let data;

      if (!validationField) {
        validationField = this.validationFields.find((_) => _.field === field);
      }

      if (event && event.target) {
        data = event.target.value;
      }
      if (typeof event === "string" || typeof event === "number") {
        data = event;
      }
      if(this.data.connectSpeed) {
        this.validationFields.push({field: 'measure.name', touched: false, validators: []})
      }
      if((field === 'measure.name' && this.data.connectSpeed)
          || (field === 'connectSpeed' && !this.data.measure)
          || (this.data.connectSpeed && !this.data.measure)) {
        if(!validationField) {
          this.validationFields.push({field: 'measure.name', touched: false, validators: []});
        }
        if(this.data.connectSpeed && !this.data.measure) {
          this.errors.push({field: 'measure.name', touched: false, validators: []})
        }
        if (this.formTouched && this.hasErrors('measure.name')) {
          this.setHasErrorClass('measure.name');
        }
        return true;
      }
      this.validationFields = this.validationFields.filter(validationField => validationField.field !== field);
      this.errors = this.errors.filter(validationField => validationField.field !== field);
      this.removeHasErrorClass('measure.name')
      return false;
    },
  },
  watch: {
    data: {
      handler() {
        if (this.data.metricLimitCondition && this.data.id && !this.isLoad) {
          this.isLoad = true;
          this.jsonParse();
        }
        if (this.data.healthModelTemplateId) {
          this.getMetricList();
        }
      },
      deep: true
    }
  }
})
