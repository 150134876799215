
import {defineComponent, PropType} from "vue";
import Accordion from "@/components/ui/Accordion.vue";
import FilterBase from "@/classes/FilterBase.vue";
import {Field} from "@/types/fields";
import {ActiveSessionFilterImp} from "@/components/classes/ActiveSessionFilterImp";
import moment from "moment/moment";

export default defineComponent({
  name: 'ActiveSessionLogFilter',
  components: {Accordion},
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter();
      },
      deep: true
    }
  },
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    applyFilter() {
      this.filterChange(new ActiveSessionFilterImp());
      if (this.creationDateStart) {
        this.filter.sessionStartTimeFrom = moment(this.creationDateStart).format("DD.MM.YYYY HH:mm:ss");
      } else {
        this.filter.sessionStartTimeFrom = null;
      }
      if (this.creationDateEnd) {
        this.filter.sessionStartTimeTo = moment(this.creationDateEnd).format("DD.MM.YYYY HH:mm:ss");
      } else {
        this.filter.sessionStartTimeTo = null;
      }
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      let queryParams: any = {};
      this.setQueryFromFilterParam(queryParams, []);
      this.setQueryParamsArrDefault(queryParams, []);
      this.$router.push({query: queryParams});
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['id']);
      this.setCheckboxesArrFromQuery(query, []);

    },
    cleanFilter() {
      this.filter = new ActiveSessionFilterImp();
      this.defaultValueFilter();
      this.setFilterFields();
      this.filterChange(new ActiveSessionFilterImp());
      this.$emit("filter", this.filter);
    },
    defaultValueFilter() {
      this.defaultValueCheckboxes([]);
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            console.log(f)
          });
    },
    handleChangePeriod(ev) {
      this.creationDateEnd = ev[1];
      this.creationDateStart = ev[0];
      this.setQueryParams();
      this.applyFilter('filter')
    },
  },
  mounted() {
    this.defaultValueFilter();
    this.getQueryParams();
    this.setFilterFields();
    this.applyFilter();
  },
  data: () => ({
    filter: new ActiveSessionFilterImp(),
    filterData: {},
    period: [],
    creationDateEnd: null,
    creationDateStart: null,
  })
})
