
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getMailNotificationData} from "@/consts/fields/mail-notification.fields";
import {CounterpartyApi} from "@/api/counterparty.api";

export default defineComponent({
  name: "MailNotificationCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getMailNotificationData(),
    counterpartyList: []
  }),
  methods: {
    onSearchCounterParty(value?) {
      CounterpartyApi.applyFilter({name: value}).then((res) => {
        this.counterpartyList = res.data;
      });
    },
    clearCounterparty(e) {
      if (e == undefined) {
        this.data.counterpartyId = null;
        this.data.counterparty.id = null;
        this.onSearchCounterParty('')
      }
    },
    onSelectCounterparty(value, option) {
      const key = "id";
      this.data.counterparty.id = option[key];
    },
  },
  mounted() {
    this.onSearchCounterParty('');
    this.getCRUDSettings(85)
  }
})

