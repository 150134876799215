
import {Options, Vue} from "vue-class-component";
import TableSettings from "@/components/base/TableSettings.vue";
import Accordion from "@/components/ui/Accordion.vue";
import DepartmentsList from "@/components/departments/DepartmentsList.vue";
import DepartmentsFilter from "@/components/departments/DepartmentsFilter.vue";
import NSIBase from "@/classes/NSIBase.vue";
import {DepartmentListData} from "@/types/department";
import {DepartmentsFilterImp} from "@/components/classes/DepartmentsFilterImp";
import {departmentsFields, getDepartmentsData} from "@/consts/fields/departments.field";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import {DepartmentsApi} from "@/api/departments.api";
import {departmentsListDtoSettings$, setVisibleToFieldsInTable} from "@/consts/settings";
import {takeWhile} from "rxjs/operators";
import {Field} from "@/types/fields";
import {ContractsApi} from "@/api/contracts.api";
import {currentUser} from "@/consts/currentUser";

@Options({
  components: {
    TableSettings,
    Accordion,
    DepartmentsList,
    DepartmentsFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as DepartmentListData[],
      search: "",
      currentUser,
      isLoadingExcel: false,
      hierarchy: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      selectedList: [],
      incidentKeys: "" as keyof DepartmentListData,
      fields: null,
      filter: new DepartmentsFilterImp(),
      prepareData: getDepartmentsData()
    };
  },
  setup() {
    return {
      locale
    }
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    setHierarchy(value: boolean) {
      this.hierarchy = value;

      this.applyFilter('filter')
    },
    getSelectedEquipmentTypes(e) {
      this.selectedList = e;
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    toggleSuffix() {
      this.search = '';
    },
    hasSupportEngineerRole() {
      return this.currentUser.value.userRoles.includes('support_engineer_internal')
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddDepartments() {
      this.$router.push("/directory/departments/add");
    },
    searchMethod(_) {
      const filter  = this.defineFilterData;
      filter[this.exceptKey] = this.$route.query.relatedId;
      filter.hierarchy = this.hierarchy
      return DepartmentsApi
          .applyFilter2(filter, this.size, this.page);
    },
    formatHierarchy(data) {
      data.children = data?.childdepartments?.length  ? data.childdepartments.map(_  => {
        return this.formatHierarchy(_);
      }) : null;
      return {...data, key: data.id, id: data.id, children:data.children};
    },
    resultMethod(res) {
      console.log(res, 'proverka')
      if(this.hierarchy) {
        this.list = res.data.data.map(_ => {
          return this.formatHierarchy(_);
        })
      }else {
        this.list = res.data.data;
      }
      console.log(this.list);
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
    getSelectedDepartments(e) {
      this.selectedList = e;
    },
    handleClickOnRelateButton() {
      if(this.exceptKey === 'exceptContractId') {
        this.relateToContract();
        return;
      }
    },
    relateToContract() {
      return ContractsApi.relateDepartmentsToContract(this.$route.query.relatedId, this.selectedList).then(() => {
        this.$router.go(-1);
      })
    },
  },
  mounted() {
    departmentsListDtoSettings$
        .pipe(
            takeWhile(() => !this.fields),
        )
        .subscribe(settings => {
          if(!settings) {
            return;
          }
          if(!settings.fields) {
            this.fields = settings;
            return;
          }
          this.fields =
              setVisibleToFieldsInTable(departmentsFields, settings.fields) as Field<DepartmentListData>[];
        });
  }
})
export default class Departments extends  Vue{
  list!: DepartmentListData[];
  search: string;
  filter: DepartmentsFilterImp;
}
