
import {defineComponent, ref} from "vue";

export default defineComponent ({
  data () {
    return {
      title: '',
      text: 'Вы действительно хотите удалить ',
      textApp: '',
      address: ''
    }
  },
  setup() {
    const visible = ref <boolean> (false);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = (e: MouseEvent) => {
      visible.value = false;
    };

    const handleCancel = () => {
      visible.value = false;
    };

    return {
      visible,
      showModal,
      handleOk,
      handleCancel
    };
  },
  emits: ["update-dialog"],
  methods: {
    openDialog(title, text) {
      this.showModal();
      this.title = `Отправить проверочное письмо`;
      this.textApp = text;
    },
    deleteObj() {
      this.$emit('update-dialog', this.address);
      this.handleOk(this.address);
      this.address = ''
    }
  }
})
