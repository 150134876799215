import axios from 'axios';
import { notification } from 'ant-design-vue';
import router from "../router";

export default function () {
    axios.interceptors.response.use((response) => {
        const url = response.request.responseURL;
        if ((url.includes('save') || url.includes('edit') )&& !url.includes('file')) {
            notification.open({
                message: 'Сохранено',
                duration: 3,
                style: {
                    fontSize: "14px",
                },
                placement: 'bottomRight'
            });
        }

        return response;
    }, (error) => {
        // if (error.config.hasOwnProperty("errorHandle") &&
        //     error.config.errorHandle === false) {
        //     return Promise.reject(error);
        // }
        if (error.response.status) {
            switch (error.response.status) {
                case 401:
                    localStorage.clear();
                    console.log(router.currentRoute.path);
                    if(router.currentRoute.value.path === '/login') {
                        break;
                    }
                    router.push({
                        path: "/login",
                        query: {
                            redirectUrl: window.location.pathname
                        }
                    });
                    break;
            }
        }
        if (error.response.data) {
            notification.open({
                message: 'Ошибка',
                description: error.response.data.message ? error.response.data.message : error.response.status === 403 ? 'Ошибка авторизации' : error.response.data,
                duration: 3,
                style: {
                    fontSize: "14px",
                },
                placement: 'bottomRight'
            });
        }

    })
}
