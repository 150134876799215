import {
  areaListDtoSettings$,
  areaTypeDtoSettings$,
  brandDtoSettings$,
  calculationRuleDtoHistorySettings$,
  calculationRuleDtoSettings$,
  collectionAgentDtoSettings$,
  contractsDtoSettings$,
  counterpartyListDtoSettings$,
  customAttributesListDtoSettings$,
  departmentsListDtoSettings$,
  groupDtoSettings$,
  incidentsSettings$,
  indicatorListDtoSettings$,
  measureDtoSettings$,
  metricGroupListDtoSettings$,
  metricListDtoWithMetricGroupsSettings$,
  calculateStatusRuleDtoSettings$,
  metricTagDtoSettings$,
  modelListDtoSettings$,
  monitoringObjectListDtoSettings$,
  monitoringObjectTypeListDtoSettings$,
  noticeTemplateDtoSettings$,
  operationQueueSettings$,
  processDefinitionDtoSettings$,
  reportsExecDtoSettings$,
  responseZoneDtoSettings$,
  serviceListDtoSettings$,
  supportTeamListDtoSettings$,
  systemLogoListDtoSettings$,
  unitDtoSettings$,
  urgencyDtoSettings$,
  userListDtoSettings$,
  fileManagerDtoSettings$,
  userRoleDtoSettings$,
  viewSettingsDtoSettings$,
  сhannelTypeDtoSettings$
} from "@/consts/settings";
import { incidentsFields } from "@/consts/fields/incidents.fields";
import { areasFields } from "@/consts/fields/areas.fields";
import { executorUsersFields } from "@/consts/fields/executor-users.fields";
import { serviceFields } from "@/consts/fields/services.fields";
import { operationQueueFields } from "@/consts/fields/operation-queues.fields";
import { brandsFields } from "@/consts/fields/brands.fields";
import { equipmentTypesFields } from "@/consts/fields/equipment-types.fields";
import { groupsFields } from "@/consts/fields/groups.fields";
import { processFields } from "@/consts/fields/processes.fields";
import { metricIndicatorFields } from "@/consts/fields/metrics-indicators.fields";
import { metricGroupsFields } from "@/consts/fields/metrics-groups.fields";
import { monitoringObjectsFields } from "@/consts/fields/monitoring-objects.fields";
import { reportsFields } from "@/consts/fields/reports.fields";
import { modelsFields } from "@/consts/fields/models.fields";
import { channelTypesFields } from "@/consts/fields/channel-types.fields";
import { areaTypesFields } from "@/consts/fields/area-types.fields";
import { metricFields } from "@/consts/fields/metrics.fields";
import { areasResponsibilityFields } from "@/consts/fields/areas-responsibility.fields";
import { supportTeamFields } from "@/consts/fields/support-team.fields";
import { systemFields } from "@/consts/fields/system.fields";
import { rolesFields } from "@/consts/fields/roles.fields";
import {unitFields} from "@/consts/fields/units.fields";
import {measureFields} from "@/consts/fields/measure.fields";
import {metricTagFields} from "@/consts/fields/metric-tag.fields";
import {calculationRulesFields} from "@/consts/fields/calculation-rule.fields";
import {noticeTemplateFields} from "@/consts/fields/notice-template.fields";
import {calculationRulesHistoryFields} from "@/consts/fields/calculation-rules-history.fields";
import {counterpartyFields} from "@/consts/fields/counterparty.fields";
import {departmentsFields} from "@/consts/fields/departments.field";
import {contractsFields} from "@/consts/fields/contracts.fields";
import {urgencyFields} from "@/consts/fields/urgency.fields";
import {fileManagerFields} from "@/consts/fields/file-manager.fields";

export const defineDtoSubject = (dto) => {
  if(dto === 'IncidentDto') {
    return incidentsSettings$;
  }
  if(dto === 'AreaStageDto') {
    return operationQueueSettings$;
  }
  if(dto === 'SystemLogoListDto') {
    return systemLogoListDtoSettings$;
  }
  if(dto === 'EntityProcessDefinitionDto') {
    return processDefinitionDtoSettings$;
  }
  if(dto === 'BrandDto') {
    return brandDtoSettings$;
  }
  if(dto === 'ModelListDto') {
    return modelListDtoSettings$;
  }
  if(dto === 'ResponseZoneDto') {
    return responseZoneDtoSettings$;
  }
  if(dto === 'ChannelTypeDto') {
    return сhannelTypeDtoSettings$;
  }
  if(dto === 'AreaTypeDto') {
    return areaTypeDtoSettings$;
  }
  if(dto === 'MonitoringObjectTypeListDto') {
    return monitoringObjectTypeListDtoSettings$;
  }
  if(dto === 'DepartmentListDto') {
    return departmentsListDtoSettings$;
  }
  if(dto === 'ContractListDto') {
    return contractsDtoSettings$;
  }
  if(dto === 'IndicatorListDto') {
    return indicatorListDtoSettings$;
  }
  if(dto === 'MetricGroupListDto') {
    return metricGroupListDtoSettings$;
  }
  if(dto === 'MetricListDtoWithMetricGroups') {
    return metricListDtoWithMetricGroupsSettings$;
  }
  if(dto === 'CalculateStatusRuleDto') {
    return calculateStatusRuleDtoSettings$;
  }
  if(dto === 'SupportTeamListDto') {
    return supportTeamListDtoSettings$;
  }
  if(dto === 'CounterpartyListDto') {
    return counterpartyListDtoSettings$;
  }
  if (dto === 'CustomAttributeDto') {
    return customAttributesListDtoSettings$;
  }
  if(dto === 'ReportsExecDto') {
    return reportsExecDtoSettings$;
  }
  if(dto === 'UrgencyDto') {
    return urgencyDtoSettings$;
  }
  if(dto === 'MonitoringObjectListDto') {
    return monitoringObjectListDtoSettings$;
  }
  if(dto === 'ServiceListDto') {
    return serviceListDtoSettings$;
  }
  if(dto === 'GroupDto') {
    return groupDtoSettings$;
  }
  if(dto === 'AreaListDto') {
    return areaListDtoSettings$;
  }
  if(dto === 'ViewSettingsDto') {
    return viewSettingsDtoSettings$;
  }
  if(dto === 'CollectionAgentDto') {
    return collectionAgentDtoSettings$;
  }
  if(dto === 'UserListDto') {
    return userListDtoSettings$;
  }
  if(dto === 'UserRoleDto') {
    return userRoleDtoSettings$;
  }
  if(dto === 'UnitDto') {
    return unitDtoSettings$;
  }
  if(dto === 'MetricTagDto') {
    return metricTagDtoSettings$;
  }
  if(dto === 'MeasureDto') {
    return measureDtoSettings$;
  }
  if(dto === 'CalculationRuleDto') {
    return calculationRuleDtoSettings$;
  }
  if(dto === 'CalculationRuleHistoryDto') {
    return calculationRuleDtoHistorySettings$;
  }
  if(dto === 'NoticeTemplateListDto') {
    return noticeTemplateDtoSettings$;
  }
  if( dto === 'FileManagerFilterDto') {
    return fileManagerDtoSettings$
  }
  return null;
}
export const defineDtoObject = (dto) => {
  if(dto === 'IncidentDto') {
    return incidentsFields;
  }
  if(dto === 'AreaListDto') {
    return areasFields;
  }
  if(dto === 'ExecutorListDto') {
    return executorUsersFields;
  }
  if(dto === 'ServiceListDto') {
    return serviceFields;
  }
  if(dto === 'AreaStageDto') {
    return operationQueueFields;
  }
  if(dto === 'BrandDto') {
    return brandsFields;
  }
  if(dto === 'EquipmentTypeDto') {
    return equipmentTypesFields;
  }
  if(dto === 'GroupDto') {
    return groupsFields;
  }
  if(dto === 'EntityProcessDefinitionDto') {
    return processFields;
  }
  if(dto === 'IndicatorListDto') {
    return metricIndicatorFields;
  }
  if(dto === 'MetricGroupListDto') {
    return metricGroupsFields;
  }
  if(dto === 'MonitoringObjectListDto') {
    return monitoringObjectsFields;
  }
  if(dto === 'DepartmentListDto') {
    return departmentsFields;
  }
  if(dto === 'ContractListDto') {
    return contractsFields;
  }
  if(dto === 'MonitoringObjectTypeListDto') {
    return equipmentTypesFields;
  }
  if(dto === 'ReportsExecDto') {
    return reportsFields;
  }
  if(dto === 'ModelListDto') {
    return modelsFields;
  }
  if(dto === 'ChannelTypeDto') {
    return channelTypesFields;
  }
  if(dto === 'AreaTypeDto') {
    return areaTypesFields;
  }
  if(dto === 'MetricListDtoWithMetricGroups') {
    return metricFields;
  }
  if(dto === 'CalculateStatusRuleDto') {
    return calculationRulesFields;
  }
  if(dto === 'MonitoringObjectListTreeDto') {
    return monitoringObjectsFields;
  }
  if(dto === 'ResponseZoneDto') {
    return areasResponsibilityFields;
  }
  if(dto === 'SupportTeamListDto') {
    return supportTeamFields;
  }
  if(dto === 'CounterpartyListDto') {
    return counterpartyFields;
  }
  if(dto === 'SystemLogoListDto') {
    return systemFields;
  }
  if(dto === 'UserListDto') {
    return executorUsersFields;
  }
  if(dto === 'UrgencyDto') {
    return urgencyFields;
  }
  if(dto === 'UserRoleDto') {
    return rolesFields;
  }
  if(dto === 'UnitDto') {
    return unitFields;
  }
  if(dto === 'MetricTagDto') {
    return metricTagFields;
  }
  if(dto === 'MeasureDto') {
    return measureFields;
  }
  if(dto === 'CalculationRuleDto') {
    return calculationRulesFields;
  }
  if(dto === 'CalculationRuleActiveChangelogDto') {
    return calculationRulesHistoryFields;
  }
  if(dto === 'NoticeTemplateListDto') {
    return noticeTemplateFields;
  }
  if (dto === 'FileManagerFilterDto') {
    return fileManagerFields
  }
  return null;
}
