
import {defineComponent} from "vue";
import ViewBase from "@/classes/ViewBase.vue";
import {HealthModelRuleApi} from "@/api/health-model-rule.api";
import HealthModelRulesCharacteristics from "@/components/health-model-rule/HealthModelRulesCharacteristics.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";

export default defineComponent({
  name: "HealthModelRulesView",
  components: {HealthModelRulesCharacteristics, DeleteDialog},
  mixins: [ViewBase],
  data: () => ({
    body: {},
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    getById() {
      let isAction = false;
      HealthModelRuleApi.getRuleById(this.id).then((res) => {
        this.body = res;
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
        if (isAction) {
          this.$refs.characteristics.action = "Создание заявки";
        }
        if(this.body.metricLimitCondition === "" && this.body.checkTime) {
          this.$refs.characteristics.incidentOpenJsonData.checkOpen = "Времени";
        }
        if(this.body.metricLimitConditionClose === "" && this.body.checkTimeClose) {
          this.$refs.characteristics.incidentCloseJsonData.checkOpen = "Времени";
        }
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      if (this.$refs.characteristics.incidentOpenJsonData.checkOpen !== 'Времени') {
        this.$refs.characteristics.data.checkTime = null;
      }
      if (this.$refs.characteristics.incidentCloseJsonData.checkOpen !== 'Времени') {
        this.$refs.characteristics.data.checkTimeClose = null;
      }
      this.$refs.characteristics.data.metricLimitCondition
          = JSON.stringify(this.$refs.characteristics.incidentOpenJsonData);
      this.$refs.characteristics.data.metricLimitConditionClose
          = JSON.stringify(this.$refs.characteristics.incidentCloseJsonData);
      const characteristics = [this.$refs.characteristics.data];
      HealthModelRuleApi.saveRule(
          characteristics
      ).then(() => {
        this.getById();
      });
    },
    deleteObject() {
      HealthModelRuleApi.deleteRule(this.id).then(() => {
        this.$router.go(-1);
      });
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog("правила модели здоровья", ` ${this.body.name}`);
    },
  }
})
