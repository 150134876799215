
import { Field } from "@/types/fields";
import { Options, Vue } from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import RolesList from "@/components/roles/RolesList.vue";
import RolesFilter from "@/components/roles/RolesFilter.vue";
import { RolesFilterImp } from "@/components/classes/RolesFilterImp";
import NSIBase from "@/classes/NSIBase.vue";
import { RolesListData } from "@/types/roles";
import { getRolesData, rolesFields } from "@/consts/fields/roles.fields";
import { RolesApi } from "@/api/roles.api";
import { setVisibleToFieldsInTable, userRoleDtoSettings$ } from "@/consts/settings";
import { takeWhile } from "rxjs/operators";
import { BrandsListData } from "@/types/brands";

@Options({
  components: {
    TableSettings,
    Accordion,
    RolesList,
    RolesFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as RolesListData[],
      search: "",
      selectedList: [],
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      userId: null,
      incidentKeys: "" as keyof RolesListData,
      fields: null,
      filter: new RolesFilterImp(),
      prepareData: getRolesData()
    };
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    toggleSuffix() {
      this.search = '';
    },
    getSelectedEquipment(e) {
      this.selectedList = e;
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddRoles() {
      this.$router.push("/security/roles/add");
    },
    searchMethod(_) {
      const filter = this.defineFilterData;
      filter[this.exceptKey] = this.$route.query.relatedId;
      return RolesApi
          .applyFilter2(filter, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
    handleClickOnRelateButton() {
      if(this.exceptKey === 'exceptUserId') {
        this.relateRolesUser();
        return;
      }
      if(this.exceptKey === 'exceptGroupId') {
        this.relateRolesGroup();
        return;
      }
    },
    relateRolesUser() {
      let data: string[] = [];
      this.selectedList.forEach(role => data.push(role+''));
      return RolesApi.addUserRoles(+this.$route.query.relatedId, data).then(() => {
        this.$router.go(-1);
      })
    },
    relateRolesGroup() {
      let data: string[] = [];
      this.selectedList.forEach(role => data.push(role+''));
      return RolesApi.addGroupRoles(this.$route.query.relatedId, data).then(() => {
        this.$router.go(-1);
      })
    },
  },
  mounted() {
    this.applyFilter();
    userRoleDtoSettings$
      .pipe(
        takeWhile(() => !this.fields),
      )
      .subscribe(settings => {
        if(!settings) {
          return;
        }
        if(!settings.fields) {
          this.fields = settings;
          return;
        }
        this.fields = setVisibleToFieldsInTable(rolesFields, settings.fields) as Field<RolesListData>[];
      });
  },
})
export default class Processes extends Vue {
  list!: RolesListData[];
  search: string;
  filter: RolesFilterImp;
}
