
import {defineComponent} from "vue";
import SystemCharacteristics from "@/components/system/SystemCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import {SystemApi} from "@/api/system.api";

export default defineComponent ({
name: 'AddSystem',
  components: {SystemCharacteristics, EditForm},
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics;
      const formData = new FormData();
      formData.append('clientId', characteristics.data.client.id)
      if(characteristics.bigLogo) {
        formData.append('bigLogo' ,characteristics.bigLogo.originFileObj, characteristics.bigLogo.name)
      }
      if(characteristics.smallLogo) {
        formData.append('smallLogo' ,characteristics.smallLogo.originFileObj, characteristics.smallLogo.name)
      }
      if(characteristics.mediumLogo) {
        formData.append('mediumLogo' ,characteristics.mediumLogo.originFileObj, characteristics.mediumLogo.name)
      }
      if(characteristics.color) {
        formData.append('color' ,characteristics.data.color)
      }
      formData.append('name', characteristics.data.name)
      formData.append('isDefault', characteristics.data.isDefault)

      SystemApi.save(
          formData
      ).then((res) => {
        this.route(res.id);
      });
    },
    route(id?) {
      this.$refs.characteristics.cleanWarning();
      if (!id) {
        this.$router.replace("/settings/system/");
        return;
      }
      this.$router.replace("/settings/system/" + id);
    },
  },
})
