
import { defineComponent } from "vue";

import BpmnModeler from 'bpmn-js/lib/Modeler';
import {
  BpmnPropertiesPanelModule,
  BpmnPropertiesProviderModule,
  CamundaPlatformPropertiesProviderModule
} from 'bpmn-js-properties-panel';

import CamundaExtensionModule from 'camunda-bpmn-moddle/lib';

import CamundaBpmnModdle from "camunda-bpmn-moddle/resources/camunda.json";
import ProcessesImportDialog from "@/components/processes/ProcessesImportDialog.vue";
import * as FileSaver from 'file-saver';
import {ProcessTemplatesApi} from "@/api/process-teplates.api";
import {suffix} from "@/consts/nameSuff";
import {currentUser} from "@/consts/currentUser";

export default defineComponent({
  name: "EditProcesses",
  components: {ProcessesImportDialog},
  data: () => ({
    id: null,
    processScIncidentID: null,
    isChange: false,
    statusesXML: '',
    xmlClone: '',
    modeler: null,
    currentUser,
    viewer: null,
  }),
  emits: ["select"],
  mounted() {
    this.id = this.$route.params.id;
    this.addHandler();
    this.prepareData();
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    addHandler() {
      let handler = document.querySelector('.handler');
      let wrapper = handler.closest<HTMLElement>('.wrapper');
      let boxA = wrapper.querySelector<HTMLElement>('.box');
      let isHandlerDragging = false;

      document.addEventListener('mousedown', function(e) {
        if (e.target === handler) {
          isHandlerDragging = true;
        }
      });

      document.addEventListener('mousemove', function(e) {
        if (!isHandlerDragging) {
          return false;
        }

        let containerOffsetLeft = wrapper.offsetLeft;
        let pointerRelativeXpos = e.clientX - containerOffsetLeft;
        let boxAminWidth = 60;

        boxA.style.width = (Math.max(boxAminWidth, pointerRelativeXpos - 8)) + 'px';
        boxA.style.flexGrow = '0';
      });

      document.addEventListener('mouseup', function(e) {
        isHandlerDragging = false;
      });
    },
    importFile() {
      this.$refs.processesImport.openDialog('Импорт');
    },
    hasAccessToSave(menuCode, childMenuCode) {
      if ( childMenuCode) {
        return !this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).children.find(res => res.menuCode === childMenuCode).update
      } else {
        return !this.currentUser.value.access.items.find((res) => res.menuCode === menuCode).update
      }
    },
    exportFile() {
      this.modeler.saveXML({ format: true }, (err: any, xml: any) => {
        if (err) {
          this.$notification.open({
            message: "Ошибка экспорта",
            description: err,
            style: {
              width: "330px",
              marginLeft: `${400 - 330}px`,
              fontSize: "14px",
            },
            placement: 'bottomRight',
            duration: 3,
          });
        }
        this.xmlClone = xml;
        const file = new Blob([this.xmlClone], {type: 'text/xml'});
        FileSaver.saveAs(file, 'process.xml');
      });
    },
    importFileDialog(file) {
      const fileForImport = new Blob([file.originFileObj], {type: 'text/xml'});
      let modeler = this.modeler;
      let changeWarning = this.changeWarning;
      fileForImport.text().then(res => {
        this.modeler.importXML(res, function(err) {
          if (!err) {
            modeler.get('canvas').zoom('fit-viewport');
            let djsContainer = document.querySelector('.djs-container');
            let svg = djsContainer.querySelector('svg');
            svg.addEventListener("mousedown",
                function () {
                  changeWarning();
                })
          } else {
            console.log('something went wrong:', err);
          }
        });
      })
    },
    changeWarning() {
      this.isChange = true;
      window.onbeforeunload = function() { return "Message" };
    },
    prepareData() {
      ProcessTemplatesApi.getProcessDefinition(this.id).then(res => {
        suffix.next(` - ${res.name}`)
        this.processScIncidentID = res.id;
        this.getProcessDefinition()
      })
    },
    createXML(changeWarning) {
      this.modeler = null;
      let canvas = document.querySelector('#canvas');
      while (canvas.firstChild) {
        canvas.removeChild(canvas.firstChild);
      }
      let properties = document.querySelector('#properties');
      while (properties.firstChild) {
        properties.removeChild(properties.firstChild);
      }

      this.modeler = new BpmnModeler({
        container: '#canvas',
        propertiesPanel: {
          parent: '#properties'
        },
        additionalModules: [
          BpmnPropertiesPanelModule,
          BpmnPropertiesProviderModule,
          CamundaPlatformPropertiesProviderModule,
          CamundaExtensionModule
        ],
        moddleExtensions: {
          camunda: CamundaBpmnModdle
        }
      });

      let mod = this.modeler;

      this.modeler.importXML(this.statusesXML, function(err) {
        if (!err) {
          console.log('success!');
          mod.get('canvas').zoom('fit-viewport');
          let djsContainer = document.querySelector('.djs-container');
          let svg = djsContainer.querySelector('svg');
          svg.addEventListener("mousedown",
              function () {
                changeWarning();
              })
        } else {
          console.log('something went wrong:', err);
        }
      });
    },
    getProcessDefinition() {
      ProcessTemplatesApi.getProcessDefinitionByID(this.processScIncidentID)
          .then(res => {
            this.statusesXML = res.bpmn20Xml;
            this.createXML(this.changeWarning);
          })
    },
    save() {
      this.modeler.saveXML({ format: true }, (err: any, xml: any) => {
        if (err) {
          return console.error('save could not export BPMN diagram xml', err);
        }
        this.xmlClone = xml;
        const file = new Blob([ this.xmlClone ], { type: 'bpmn' });
        const formData = new FormData();
        formData.append('data', file, 'process.bpmn');
        // formData.append('tenant-id', null);
        // formData.append('deployment-source', null);
        formData.append('deploy-changed-only', 'true');
        formData.append('enable-duplicate-filtering', 'true');
        formData.append('deployment-name', '');
        formData.append('deployment-activation-time', '');
        ProcessTemplatesApi.deploymentCreate(formData)
            .then(() => {
              this.$notification.open({
                message: "Сохранено",
                style: {
                  width: "330px",
                  marginLeft: `${400 - 330}px`,
                  fontSize: "14px",
                },
                placement: 'bottomRight',
                duration: 3,
              });
            });
      });
    },
    // async updateXML(to, from, next) {
    //   console.log(this.modeler.isContentEditable);
    //   await this.modeler.saveXML({ format: true }, (err: any, xml: any) => {
    //     if (err) {
    //       this.xmlClone = '';
    //     } else
    //       this.xmlClone = xml;
    //
    //     if (this.xmlClone == this.statusesXML) {
    //       window.onbeforeunload = undefined;
    //       next();
    //       return;
    //     }
    //     const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    //     if (answer) {
    //       window.onbeforeunload = undefined;
    //       next();
    //     } else {
    //       next(false);
    //     }
    //   });
    // },
  },
  beforeRouteLeave(to, from, next) {
    // const result = await this.updateXML(to, from, next);
    // return result;
    if (!this.isChange) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  beforeUnmount() {
    this.modeler = null;
    this.statusesXML = '';
    this.xmlClone = '';
    this.isChange = false;
    suffix.next('');
  }
});
