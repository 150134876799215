
import { defineComponent } from "vue";
import {MonitoringObjectTypesApi} from "../../api/monitoring-object-types.api";
import EquipmentTypesCharacteristics from "@/components/equipment-types/EquipmentTypesCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase from "@/classes/ViewBase.vue";
import SupportTeamsFilter from "@/components/support-teams/SupportTeamsFilter.vue";
import SupportTeamList from "@/components/support-teams/SupportTeamList.vue";
import MetricList from "@/components/metrics/MetricList.vue";
import MetricsFilter from "@/components/metrics/MetricsFilter.vue";
import {MetricApi} from "@/api/metric.api";
import {suffix} from "@/consts/nameSuff";
import {currentUser} from "@/consts/currentUser";

export default defineComponent({
  name: "EquipmentTypesView",
  components: { EquipmentTypesCharacteristics,
    EditForm,  DeleteDialog, SupportTeamsFilter, SupportTeamList, MetricList, MetricsFilter },
  mixins: [ViewBase],
  data: () => ({
    body: {},
    selectorData: [
        {value: 'Типы метрик', menuCode: 'dictionary', childMenuCode: 'types_of_metrics'}
    ],
    currentSelect: 'Типы метрик',
    type: 'equipment-types',
    currentUser
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();

    const accessParams = { module: "dictionary", object: "types_of_objects" };
    const tabMap = {
      "tab_common_information_types_of_objects": "1",
      "tab_relation_types_types_of_objects": "3",
    };
    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("тип объекта", `тип объекта ${this.body.name}`);
    },
    getById() {
      MonitoringObjectTypesApi.getById(this.id).then((res) => {
        if (!res.parent) {
         res.parent = {id: null, name: ''}
        }
        this.body = res;
        suffix.next(' ' + res.name);
      }).finally(() => {
        this.$refs.characteristics?.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics?.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristics();
      MonitoringObjectTypesApi.edit(
          characteristics
      ).then(() => {
        this.getById();
      });
    },
    deleteObject() {
      MonitoringObjectTypesApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
    handleDeleteRelatedObject(id) {
      if(this.currentSelect ==='Типы метрик') {
        MetricApi.deleteMetricTypeFromMonitoringObjectType(id, this.id)
            .then(res => {
              this.applyCurrentRelatedFilter();
            })
      }
    }
  },
});
