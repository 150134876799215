
import { reactive } from "vue";
import { Field } from "@/types/fields";
import { Options, Vue } from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import { GroupsFilterImp } from "@/components/classes/GroupsFilterImp";
import NSIBase from "@/classes/NSIBase.vue";
import { GroupsListData } from "@/types/groups";
import { ImportsListData } from "@/types/imports";
import { getImportsData, importsFields } from "@/consts/fields/imports.fields";
import { ReportsApi } from "@/api/reports.api";
import ImportList from "@/components/imports/ImportList.vue";
import { ReportsFilterImp } from "@/components/classes/ReportsFilterImp";
import ReportsFilter from "@/components/reports/ReportsFilter.vue";
import { first, interval, Subscription } from "rxjs";

@Options({
  components: {
    TableSettings,
    Accordion,
    ImportList,
    ReportsFilter,
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as ImportsListData[],
      search: "",
      selectedList: [],
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      userId: null,
      incidentKeys: "" as keyof ImportsListData,
      fields: reactive(importsFields.slice() as Field<ImportsListData>[]),
      filter: new ReportsFilterImp(),
      prepareData: getImportsData(),
    };
  },
  methods: {
    getSelectedEquipment(e) {
      this.selectedList = e;
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields);
    },
    applyFilter(from?: "filter" | unknown) {
      if (from !== "filter") {
        if (this.search !== "") this.applyFilterRequest$.next("containsValue");
        else this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter() {
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = "";
      let column = value.columnKey;

      if (value.order) {
        order = value.order === "descend" ? "desc" : "asc";
      } else {
        this.filter.sortParams = [{ sortFieldName: "name", direction: "asc" }];
        this.applyFilter();
        return;
      }
      let sortParams: any = { sortFieldName: column, direction: order };
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAdd() {
      this.$router.push("/settings/imports/add");
    },
    searchMethod(_) {
      const filter = this.defineFilterData;
      filter[this.exceptKey] = +this.$route.query.relatedId;
      this.filter.typeIds = [24]
      if (this.search) {
        return ReportsApi.applyFilter2(
            _ ? {containsValue: this.search*1, sortParams: this.filter.sortParams, typeIds: this.filter.typeIds}
                : this.filter,
            this.size,
            this.page,
            {}
        );
      }
      else {
        return ReportsApi.applyFilter2(
            _ ? { sortParams: this.filter.sortParams, typeIds: this.filter.typeIds}
                : this.filter,
            this.size,
            this.page,
            {}
        );
      }
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
      this.setApplyFilterIntervalForEmptyParamImport(res.data.data);
    },
    setApplyFilterIntervalForEmptyParamImport(data) {
      let hasEmptyLink = false;
      data.map(({ link }) => {
        if (link) {
          return;
        }
        hasEmptyLink = true;
      });
      if (hasEmptyLink) {
        interval(5000)
          .pipe(first())
          .subscribe((_) => {
            this.applyFilter();
          });
        return;
      }
    },
  },
  mounted() {
    this.applyFilter();
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
})
export default class Processes extends Vue {
  list!: GroupsListData[];
  search: string;
  filter: GroupsFilterImp;
}
