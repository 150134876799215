
import {defineComponent} from "vue";
import DepartmentsCharacteristics from "@/components/departments/DepartmentsCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase from "@/classes/ViewBase.vue";
import {DepartmentsApi} from "@/api/departments.api";
import ExecutorUsersList from "@/components/executor-users/ExecutorUsersList.vue";
import ExecutorUsersFilter from "@/components/executor-users/ExecutorUsersFilter.vue";
import {suffix} from "@/consts/nameSuff";
import {currentUser} from "@/consts/currentUser";

export default defineComponent({
  name: "DepartmentsView",
  components: {
    DepartmentsCharacteristics,
    EditForm,
    DeleteDialog,
    ExecutorUsersList,
    ExecutorUsersFilter
  },
  mixins: [ViewBase],
  data: () => ({
    body: {},
    selectorData: [
        {value: 'Пользователи', menuCode: 'security', childMenuCode: 'users' }
    ],
    currentSelect: 'Пользователи',
    type: 'department',
    currentUser
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();

    const accessParams = { module: "dictionary", object: "divisions" };
    const tabMap = {
      "tab_common_information_divisions": "1",
      "tab_relation_divisions": "3",
    };
    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("подразделения", `подразделение ${this.body.name}`);
    },
    getById() {
      DepartmentsApi.getById(this.id).then((res) => {
        this.body = res;
        suffix.next(' ' + res.name);
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristics();
      DepartmentsApi.save(characteristics)
          .then(() => {
            this.getById()
          })
    },
    deleteObject() {
      DepartmentsApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
    handleDeleteRelatedObject(id) {
      if(this.currentSelect ==='Пользователи') {
        DepartmentsApi.deleteUsersFromDepartments(this.id,id)
            .then(res => {
              this.applyCurrentRelatedFilter();
            })
      }
    }

  }
})
