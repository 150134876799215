import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2425ca52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pl-4 pr-4 pb-5 mb-4 view-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SystemCharacteristics = _resolveComponent("SystemCharacteristics")!
  const _component_EditForm = _resolveComponent("EditForm")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_delete_dialog = _resolveComponent("delete-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_tabs, null, {
        default: _withCtx(() => [
          (_ctx.hasAccessToRead('settings','system', 'tab_common_information_system'))
            ? (_openBlock(), _createBlock(_component_a_tab_pane, {
                key: "1",
                tab: "Общая информация"
              }, {
                default: _withCtx(() => [
                  (!!_ctx.body)
                    ? (_openBlock(), _createBlock(_component_EditForm, {
                        key: 0,
                        onSave: _ctx.save,
                        onReject: _ctx.cancel,
                        onDelete: _ctx.openDeleteDialog,
                        data: {id: _ctx.body.id ,
        isHasSave: _ctx.hasAccessToSave('settings','system', 'tab_common_information_system'),
         isDel: _ctx.hasAccessToDelete('settings','system', 'tab_common_information_system')}
                      }, {
                        default: _withCtx(() => [
                          (!!_ctx.body)
                            ? (_openBlock(), _createBlock(_component_SystemCharacteristics, {
                                key: 0,
                                ref: "characteristics",
                                body: _ctx.body
                              }, null, 8, ["body"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["onSave", "onReject", "onDelete", "data"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_delete_dialog, {
      ref: "delete",
      onUpdateDialog: _ctx.deleteObject
    }, null, 8, ["onUpdateDialog"])
  ], 64))
}