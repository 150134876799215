
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getAreaTypesData} from "@/consts/fields/area-types.fields";

export default defineComponent({
  name: "AreaTypesCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getAreaTypesData(),
    validationFields:[
      {field: "name", validators: ["required"]}
    ],
  }),
  methods: {
  },
  mounted() {
    this.getCRUDSettings(104)
  }
})
