
import {defineComponent} from "vue";
import ViewBase from "@/classes/ViewBase.vue";
import {SystemApi} from "@/api/system.api";
import SystemCharacteristics from "@/components/system/SystemCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {suffix} from "@/consts/nameSuff";

export default defineComponent
({

  name: "SystemView",
  components: {SystemCharacteristics, EditForm ,DeleteDialog},
  mixins: [ViewBase],
  data: () => ({
    body: null,
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("процесса", `процесс ${this.body.id}`);
    },
    getById() {
      SystemApi.getById(this.id).then((res) => {
        this.body = res;
        suffix.next(' ' + res.name);
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
     const characteristics = this.$refs.characteristics;
     //  const characteristics = JSON.parse(JSON.stringify(this.$refs.characteristics));
      const formData = new FormData();
      formData.append('clientId', characteristics.data.client.id)
      formData.append('id', characteristics.data.id)
      if(characteristics.bigLogo) {
        formData.append('bigLogo' ,characteristics.bigLogo.originFileObj, characteristics.bigLogo.name)
      }
      if(characteristics.smallLogo) {
        formData.append('smallLogo' ,characteristics.smallLogo.originFileObj, characteristics.smallLogo.name)
      }
      if(characteristics.mediumLogo) {
        formData.append('mediumLogo' ,characteristics.mediumLogo.originFileObj, characteristics.mediumLogo.name)
      }
      formData.append('color' ,characteristics.data.color)
      formData.append('name', characteristics.data.name)
      formData.append('isDefault', characteristics.data.isDefault)
      SystemApi.save(
          formData
      ).then(() => {
        this.getById();
      });
    },
    deleteObject() {
      SystemApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
  },
})
