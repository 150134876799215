
import {Options, Vue} from "vue-class-component";
import {EquipmentTypeListData} from "@/types/equipment-type";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import Accordion from "@/components/ui/Accordion.vue";
import EquipmentTypesList from "@/components/equipment-types/EquipmentTypesList.vue";
import {MonitoringObjectTypesApi} from "@/api/monitoring-object-types.api";
import TableSettings from "@/components/base/TableSettings.vue";
import EquipmentTypesFilter from "@/components/equipment-types/EquipmentTypesFilter.vue";
import {EquipmentTypesFilterImp} from "@/components/classes/EquipmentTypesFilterImp";
import {getEquipmentTypesData, equipmentTypesFields} from "@/consts/fields/equipment-types.fields";
import NSIBase from "@/classes/NSIBase.vue";
import {monitoringObjectTypeListDtoSettings$, setVisibleToFieldsInTable} from "@/consts/settings";
import {takeWhile} from "rxjs/operators";
import {Field} from "@/types/fields";
import {SupportTeamApi} from "@/api/supportTeam.api";
import {MetricApi} from "@/api/metric.api";
import {currentUser} from "@/consts/currentUser";

@Options({
  components: {
    TableSettings,
    Accordion,
    EquipmentTypesList,
    EquipmentTypesFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as EquipmentTypeListData[],
      search: "",
      isLoadingExcel: false,
      hierarchy: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      incidentKeys: "" as keyof EquipmentTypeListData,
      fields: null,
      filter: new EquipmentTypesFilterImp(),
      prepareData: getEquipmentTypesData(),
      currentUser
    };
  },
  setup() {
    return {
      locale
    }
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    getSelectedEquipmentTypes(e) {
      this.selectedList = e;
    },
    handleClickOnRelateButton() {
      if(this.exceptKey === 'exceptSupportTeamId') {
        this.relateEquipmentTypeToSupportTeam();
      }
      if(this.exceptKey === 'exceptMetricId') {
        this.relateEquipmentTypeToMetricType();
      }
    },
    hasSupportEngineerRole() {
      return this.currentUser.value.userRoles.includes('support_engineer_internal')
    },
    relateEquipmentTypeToSupportTeam() {
      SupportTeamApi.addMonitoringObjectTypes(this.$route.query.relatedId, this.selectedList).then(_ => {
        this.$router.go(-1);
      })
    },
    relateEquipmentTypeToMetricType() {
      MetricApi.addMetricTypeToMonitoringObjectType(this.$route.query.relatedId, this.selectedList).then(_ => {
        this.$router.go(-1);
      })
    },
    setHierarchy(value: boolean) {
      this.hierarchy = value;

      this.applyFilter('filter')
    },
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      MonitoringObjectTypesApi
              .downloadExcel(filter)
              .then((res) => {
                this.$notification.open({
                  message: new TextDecoder().decode(res.data),
                  style: {
                    width: '600px',
                    marginLeft: `${400 - 600}px`,
                    fontSize: "14px",
                  },
                  placement: 'bottomRight',
                  duration: 3,
                });
              })
              .finally(() => (this.isLoadingExcel = false));
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    toggleSuffix() {
      this.search = '';
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddEquipmentTypes() {
      this.$router.push("/directory/equipment-types/add");
    },
    searchMethod(_) {
      const filter  = this.defineFilterData;
      filter[this.exceptKey] = this.$route.query.relatedId;
      filter.hierarchy = this.hierarchy
      console.log(filter, 'ПРОВЕРКА ФИЛЬТРА ПРОВЕРКА ФИЛЬТРА')
      return MonitoringObjectTypesApi
          .applyFilter2(filter, this.size, this.page);
    },
    formatHierarchy(data) {
      data.children = data?.childMonitoringObjectTypes?.length  ? data.childMonitoringObjectTypes.map(_  => {
       return this.formatHierarchy(_);
      }) : null;
      return {...data, key: data.id, id: data.id, children:data.children};
    },
    resultMethod(res) {
      if(this.hierarchy) {
        this.list = res.data.data.map(_ => {
          return this.formatHierarchy(_);
        })
      }else {
        this.list = res.data.data;
      }
      console.log(this.list);
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    monitoringObjectTypeListDtoSettings$
        .pipe(
            takeWhile(() => !this.fields),
        )
        .subscribe(settings => {
          if(!settings) {
            return;
          }
          if(!settings.fields) {
            this.fields = settings;
            return;
          }
          this.fields
              = setVisibleToFieldsInTable(equipmentTypesFields, settings.fields) as Field<EquipmentTypeListData>[];
        });
  },
})
export default class EquipmentTypes extends Vue {
  list!: EquipmentTypeListData[];
  search: string;
  filter: EquipmentTypesFilterImp;
}
