
import {defineComponent} from "vue";
import {getProcessesData} from "@/consts/fields/processes.fields";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {ProcessApi} from "@/api/process.api";

export default defineComponent({
  name: "ProcessCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getProcessesData(),
    processList: [],
    entityTypeList: [],
    validationFields:[
    ],
  }),
  mounted() {
    this.searchEntityType();
    this.searchProcesses()
  },
  methods: {
    searchEntityType() {
      ProcessApi.getEntityType('')
          .then((res) => {
            this.entityTypeList = res;
          });
    },
    searchProcesses() {
      ProcessApi.getProcessDefinition(this.data.processDefinitionKey)
          .then((res) => {
            this.processList = res;
          });
    },
    selectProcesses(value, option) {
      this.data.processDefinitionKey = option.value;
    },
    onSelect(value, option) {
      this.data[option.field] = {
        name: option.value,
        id: option.id,
      };
    }
  }
})
