
import HealthModelRulesCharacteristics from "@/components/health-model-rule/HealthModelRulesCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import {HealthModelRuleApi} from "@/api/health-model-rule.api";
import {defineComponent} from "vue";

export default defineComponent({
  name: "HealthModelRulesAdd",
  components: {HealthModelRulesCharacteristics, EditForm},
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      // console.log(!!this.$refs.characteristics.incidentOpenJsonData.type)
      // if (!this.$refs.characteristics.incidentOpenJsonData.type) {
      //   return;
      // }
      if(this.$refs.characteristics.validate()) {
        return;
      }

      const characteristics = [this.$refs.characteristics.data];
      HealthModelRuleApi.saveRule(
          characteristics
      ).then((res) => {
        console.log(res)
        this.route(res[0].id);
      });
    },
    route(id) {
      this.$refs.characteristics.cleanWarning();
      this.$router.replace("/directory/health-model-rules/" + id);
    },
  },
  mounted() {

  }


})
