
import {defineComponent} from "vue";
import ListBase from "@/classes/ListBase.vue";

export default defineComponent({
  mixins: [ListBase],
  methods:{},
  data: () => {
    return {}
  },
  mounted() {

  }
})
