
import { defineComponent } from "vue";
import ListBase from "@/classes/ListBase.vue";
import { ReportsApi } from "@/api/reports.api";
import * as FileSaver from 'file-saver';
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";

export default defineComponent({
  mixins: [ListBase],
  components: {DeleteDialog},
  methods: {
    deleteRelatedReport() {
      ReportsApi.delete(this.id).then(() => {
        this.onChange(this.currentPage, this.currentSize);
      });
    },
    openDeleteDialog(id, name) {
      this.id = id;
      this.$refs.delete.openDialog("импорта данных", `отчет ${name}`);
    },
    downloadFile(record) {
        ReportsApi.downloadReport(record)
          .then(res => {
            const disposition = res.headers['content-disposition'].split('filename=')[1];
            FileSaver.saveAs(res.data, disposition);
          })
    }
  }
});
