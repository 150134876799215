import axios from 'axios';

export default function () {
  axios.interceptors.request.use(config => {
    // if (keycloak.authenticated) {
    //   config.headers.Authorization = `Bearer ${keycloak.token}`
    // }
    if (localStorage.getItem('auth_token')) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('auth_token')}`;
    }
    // if (!options.preventClearHashAfterLogin) {
    const href = location.href
        .replace(/[&?]code=[^&$]*/, '')
        .replace(/[&?]scope=[^&$]*/, '')
        .replace(/[#?]state=[^&$]*/, '')
        .replace(/[&?]state=[^&$]*/, '')
        .replace(/[&?]session_state=[^&$]*/, '');
    history.replaceState(null, window.name, href);
    // }

    config.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0';
    config.headers['Set-Cookie'] = 'token=deleted; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    config.headers['Surrogate-Control'] = 'no-store';
    config.headers.Pragma = 'no-cache';
    config.headers.Expires = '0';

    return config;
  }, error => {
    return Promise.reject(error)
  })
}
