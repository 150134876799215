
import {defineComponent} from "vue";
import SupportTeamCharacteristics from "@/components/support-teams/SupportTeamCharacteristics.vue";
import {SupportTeamApi} from "../../api/supportTeam.api";
import EditForm from "@/components/base/EditForm.vue";
export default defineComponent({
  name: "SupportTeamAdd",
  components: { SupportTeamCharacteristics, EditForm },
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const contacts = this.$refs.characteristics.data.contacts;
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      let contractsInfo = [];
      Object.keys(contacts).forEach(key => {
        contractsInfo.push(contacts[key]);
      });
      characteristics.contacts = contractsInfo;
      characteristics.status = characteristics.isStatus ? {name: "Активен", id: 1} : {name: "Неактивен", id: 3};

      SupportTeamApi.save(
          characteristics
      ).then((res) => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.route(res.id);
      });
    },
    route(id?) {
      this.$refs.characteristics.cleanWarning();
      this.$router.replace("/directory/support-teams/" + id);
    },
  },
});
