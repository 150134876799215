export const defineValueTypeAggregation = (value) => {
  if (!value) {
    return '';
  }
  if (value === 'max') {
    return 'Максимум';
  }
  if (value === 'min') {
    return 'Минимум';
  }
  if (value === 'avg') {
    return 'Cреднее';
  }
  if (value === 'sum') {
    return 'Сумма';
  }
  return value;
}

export const defineValueTypeInterpolation = (value) => {
  if (!value) {
    return '';
  }
  if (value === 'prev') {
    return 'Предыдущее значение';
  }
  if (value === 'default') {
    return 'Значение по умолчанию';
  }
  return value;
}

export const defineValueTypeWorkTime = (value) => {
  if (!value) {
    return '';
  }
  if (value === 'allTime') {
    return 'Вычисление производится постоянно';
  }
  if (value === 'workTime') {
    return 'Вычисление производится только в рабочие часы';
  }
  return value;
}
