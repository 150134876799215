import axios from "axios";

export const url = "/skyv-mon/api";
export const withoutAPIUrl = "/skyv-mon";
export const engineRestUrl = "/skyv-mon/engine-rest";
export const urlAdmin = `${url}/adminapi`;

export const send = (obj: any): any => {
  const sendObj = Object.assign({}, obj);


  return axios(sendObj).then(res => {
    return res.data
  });
};

export const sendMultipartFile = (obj: any) : any => {
  const sendObj = Object.assign({}, obj);

  return axios(sendObj).then(res => {
    return res.data
  });
}


export const send2 = (obj: any): any => {
  const sendObj = Object.assign({}, obj);

  return axios(sendObj).then(res => {
    return res
  });
};
