
import {defineComponent} from "vue";
import {getAreasResponsibilityData} from "@/consts/fields/areas-responsibility.fields";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";

export default defineComponent({
  name: "AreasResponsibilityCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getAreasResponsibilityData(),
    validationFields:[
      {field: "name", validators: ["required"]},
    ],
  }),
  methods: {
  },
  mounted() {
    this.getCRUDSettings(111)
  }
})
