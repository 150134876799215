
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import { getMailData } from "@/consts/fields/mail.fields";
import { defineComponent } from "vue";
import { MailApi } from "@/api/mail.api";
import MailSendDialog from "@/components/mail/MailSendDialog.vue";
import moment from "moment";

export default defineComponent({
  name: "MailCharacteristics",
  mixins: [CharacteristicBase, MailSendDialog],
  data: () => ({
    showPassword: "",
    password: "",
    data: getMailData(),
    encriptTypeList: [{name: 'SSL'}, {name: 'TLS'}]
  }),
  methods: {
    sendMessage(e) {
      MailApi.sendMessages({ to: e });
    },
    openSendDialog() {
      this.$parent.emitSend()

    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
  computed: {
    formatDateConnect() {
        return moment(String(this.data.dateConnect)).format('DD.MM.YYYY HH:mm')
    },
    formatDateConnectSuccess() {
        return moment(String(this.data.dateConnectSuccess)).format('DD.MM.YYYY HH:mm')
    },
  },
  mounted() {
    this.data.password = '********'
    this.getCRUDSettings(109)
  },
});
