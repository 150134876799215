
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import { getImportsData } from "@/consts/fields/imports.fields";
import { ReportsApi } from "@/api/reports.api";
import {MonitoringObjectTypesApi} from "@/api/monitoring-object-types.api";
import * as FileSaver from 'file-saver';

export default defineComponent({
  name: "ImportCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getImportsData(),
    validationFields:[
      {field: "objectName", validators: ["required"]},
      {field: "packageCount", validators: ["required"]},
    ],
    uploadObjects: [],
    statusList:[]
  }),
  mounted() {
    this.getUploadObjects();
  },
  methods: {
    customRequest(ev) {
      console.log(ev);
    },
    getUploadObjects() {
      ReportsApi.getUploadObjects()
        .then(res => {
          this.uploadObjects = res;
        })
    },
    handleChange(files) {
      files.fileList = files.fileList.map(_ => {_.status = 'done'; return _})
      this.file = files.fileList[0];
    },

    getTemplate() {
      MonitoringObjectTypesApi.downloadTemplate(this.data.objectName)
          .then(data => {
        console.log(data)
        const disposition = data.headers['content-disposition'].split('filename=')[1];
        FileSaver.saveAs(data.data, disposition);
      })
    }
  }
})
