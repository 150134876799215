
import {defineComponent, reactive} from "vue";
import ViewBase from "@/classes/ViewBase.vue";
import {CalculationRuleApi} from "@/api/calculationRule.api";
import {HealthModelRuleApi} from "@/api/health-model-rule.api";
import HealthModelRuleCharacteristics from "@/components/health-model-rule/HealthModelRuleCharacteristics.vue";
import {HealthModelRuleFields} from "@/consts/fields/health-model-rule.fields";
import {Field} from "@/types/fields";
import {HealthModelRuleListData} from "@/types/health-model-rule";
import {HealthModelRulesListData} from "@/types/health-model-rules";
import {HealthModelRulesFields, HealthModelRulesRefreshFields} from "@/consts/fields/health-model-rules.fields";
import HealthModelRulesList from "@/components/health-model-rule/HealthModelRulesList.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import {HealthModelRulesFilterImp} from "@/components/classes/HealthModelRulesFilterImp";
import HealthModelRulesFilter from "@/components/health-model-rule/HealthModelRulesFilter.vue";
import MonitoringObjectsList from "@/components/monitoring-objects/MonitoringObjectsList.vue";
import MonitoringObjectsFilter from "@/components/monitoring-objects/MonitoringObjectsFilter.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {HealthModelRuleRefresh} from "@/types/health-model-rule-refresh";
import {suffix} from "@/consts/nameSuff";
import {currentUser} from "@/consts/currentUser";

export default defineComponent(
    {
      name: "HealthModelRuleView",
      components:{
        HealthModelRuleCharacteristics,
        HealthModelRulesList,
        TableSettings,
        HealthModelRulesFilter,
        MonitoringObjectsList,
        MonitoringObjectsFilter,
        DeleteDialog
      },
      mixins: [ViewBase],
      data: () => ({
        body: {},
        rulesData: [],
        fields: reactive(HealthModelRulesFields.slice() as Field<HealthModelRulesListData>[]),
        fieldsRefresh: reactive(HealthModelRulesRefreshFields.slice() as Field<HealthModelRuleRefresh>[]),
        list: [] as HealthModelRulesListData[],
        listRefresh: [] as HealthModelRuleRefresh[],
        visibleSidebarFilter: false,
        visibleSidebarTableSettings: false,
        totalCount: 0,
        totalCountRefresh: 0,
        size: 20,
        page: 1,
        listPage: 1,
        sizeRefresh: 20,
        pageRefresh: 0,
        listPageRefresh: 1,
        filter: new HealthModelRulesFilterImp(),
        selectorData: [
            {value: 'Объекты', menuCode: 'monitoring', childMenuCode: 'object'}
        ],
        currentSelect: 'Объекты',
        type: 'health-model-rule',
        visible: false,
        message: '',
        current: 0,
        currentUser,
        steps: [
          {
            title: 'Проверка связывания',
            content: 'Первый список',
          },
          {
            title: 'Проверка вторая',
            content: 'Второй список',
          },
          {
            title: 'Проверка третья',
            content: 'Третий список',
          },
        ],
        monitoringObjectList:[]
      }),
      mounted() {
        this.id = this.$route.params.id;
        this.getById();
        this.getHealthModelRules();

        const accessParams = { module: 'dictionary', object: 'template_for_health_models' };
        const tabMap = {
          'tab_common_information_template_for_health_models': '1',
          'tab_relation_types_template_for_health_models': '2',
          'tab_rule_types_template_for_health_models': '3',
        };
        this.activeKey = this.determineActiveTab(accessParams, tabMap);
      },
      beforeRouteLeave(to, from, next) {
        suffix.next(null);
        if (!this.$refs.characteristics?.isWarning) {
          window.onbeforeunload = undefined;
          next();
          return;
        }
        const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
        if (answer) {
          window.onbeforeunload = undefined;
          next();
        } else {
          next(false);
        }
      },
      methods: {
        showModel() {
          this.visible = true;
          this.getRefreshList();
        },
        handleOk() {
          this.visible = false;
        },
        next() {
          this.current++
        },
        prev() {
          this.current--
        },
        getRefreshList() {
          HealthModelRuleApi.getRefreshList({healthModelTemplateId: this.id}, this.sizeRefresh, this.pageRefresh).then((res) => {
            res.data = res.data.map((res) => {res.checked = true
              return res})
            this.listRefresh = res.data
            this.totalCountRefresh = res.totalCount

            this.monitoringObjectList = res.data.map((res) => {
              return res.id
            })
          })
        },
        getById() {
          HealthModelRuleApi.getById(this.id).then((res) => {
            this.body = res;
            suffix.next(' ' + res.name);
          }).finally(() => {
            this.$refs.characteristics.cleanWarning();
          });
        },
        save() {
          if(this.$refs.characteristics.validate()) {
            return;
          }
          const characteristics = this.$refs.characteristics.data;
          HealthModelRuleApi.save(
              characteristics
          ).then(() => {
            this.getById();
          });
        },
        deleteObject() {
          HealthModelRuleApi.deleteHealthModel(this.id).then(() => {
            this.$router.go(-1);
          });
        },
        getHealthModelRules(){
          HealthModelRuleApi.getHealthModelRules({healthModelTemplateId: this.id}).then((res)=> {
            this.rulesData = res.data;
            this.list = res.data
            this.totalCount = res.totalCount
            this.isLoad = true;
          })
        },
        updatePages({page, size}) {
          if (this.size !== size) {
            this.page = 0;
            this.listPage = 1;
          } else {
            this.page = page - 1;
            this.listPage = page;
          }

          this.size = size;
          this.getHealthModelRules();
        },
        updatePagesRefresh({page, size}) {
          if (this.sizeRefresh !== size) {
            this.pageRefresh = 0;
            this.listPageRefresh = 1;
          } else {
            this.pageRefresh = page - 1;
            this.listPageRefresh = page;
          }

          this.sizeRefresh = size;
          this.getRefreshList();
        },
        updateLists(value) {
          let order = '';
          let column = value.columnKey;

          if(value.order) {
            order = value.order === 'descend' ? 'desc': 'asc';
          } else {
            this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
            this.getHealthModelRules();
            return;
          }
          let sortParams: any =
              {sortFieldName: column, direction: order};
          this.filter.sortParams = [sortParams];
          this.getHealthModelRules();
        },
        filterUpdates(filter) {
          if(!this.$route.query.realtedId) {
            filter[this.exceptKey] = this.$route.query.realtedId;
          }
          this.filter = Object.assign({}, filter);
          if(!this.isFirstLoaded && this.search) {
            this.isFirstLoaded = true;
            return;
          }
          this.getHealthModelRules('filter');
        },
        openDeleteDialog() {
          this.$refs.delete.openDialog("шаблона модели здоровья", ` ${this.body.name}`);
        },
        routeToAddRule() {
          this.$router.push({
            path: `/directory/health-model-rules/add`,
            query: {
              relatedId: this.$route.params.id,
            },
          });
        },
        acceptRefreshHealthModelRule() {
          HealthModelRuleApi.acceptRefreshList({healthModelTemplateId: this.id, monitoringObjects: this.monitoringObjectList}).then((res) => {
            this.$notification.open({
              message: "Cвязи обновлены",
              style: {
                width: "330px",
                marginLeft: `${400 - 330}px`,
                fontSize: "14px",
              },
              placement: 'bottomRight',
              duration: 3,
            });
            this.handleOk();
            this.updatePage({page: 1, size: 20})
          })
        },
        unSelectRelate(e){
          this.monitoringObjectList.splice(this.monitoringObjectList.findIndex(res => res == e), 1)
        },
        selectRelate(e) {
          this.monitoringObjectList.push(e)
        },
        test1() {
          console.log(123)
        }
      }
    }
)
