import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dcd76462"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "incidents content-container" }
const _hoisted_2 = { class: "flex justify-content-between align-items-center" }
const _hoisted_3 = { style: {"width":"20vw","height":"25vh","border":"1px solid #BDBDBD","border-radius":"5%","text-align":"center"} }
const _hoisted_4 = { style: {"justify-content":"center","font-size":"22px","font-weight":"600","color":"#3D535C","margin-top":"10px"} }
const _hoisted_5 = { style: {"font-size":"16px","color":"#3D535C","margin-top":"10px","margin-left":"1px","margin-right":"1px"} }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_row, { class: "raw" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuData, (item) => {
            return (_openBlock(), _createBlock(_component_a_col, {
              span: 7,
              style: {"margin":"20px"},
              class: _normalizeClass([_ctx.hasAnyActiveChapter(item) ? '' : 'display-none'])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(item.title), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.childTitle, (childItem, index) => {
                    return (_openBlock(), _createElementBlock("span", _hoisted_5, [
                      _createElementVNode("a", {
                        class: _normalizeClass([_ctx.hasAccessToRead(childItem) ? '' : 'display-none']),
                        onClick: ($event: any) => (_ctx.routeToUrl(childItem.url))
                      }, _toDisplayString(childItem.title), 11, _hoisted_6),
                      _createElementVNode("span", {
                        class: _normalizeClass([ _ctx.hasAccessToRead(childItem) ? '' : 'display-none'])
                      }, _toDisplayString(index < item.childTitle.length - 1 ? ', ' : ''), 3)
                    ]))
                  }), 256))
                ])
              ]),
              _: 2
            }, 1032, ["class"]))
          }), 256))
        ]),
        _: 1
      })
    ])
  ]))
}