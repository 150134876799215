
import {defineComponent} from "vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import EditForm from "@/components/base/EditForm.vue";
import ViewBase from "@/classes/ViewBase.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import RoleCharacteristics from "@/components/roles/RoleCharacteristics.vue";
import {RolesApi} from "@/api/roles.api";
import {suffix} from "@/consts/nameSuff";
import RoleObjectsList from "@/components/role-objects/RoleObjectsList.vue";
import RoleObjectsFilter from "@/components/role-objects/RoleObjectsFilter.vue";

export default defineComponent({
  name: "RoleView",
  components: {
    RoleObjectsList, RoleObjectsFilter,
    RoleCharacteristics,
    EditForm,
    DeleteDialog,
    TableSettings
  },
  mixins: [ViewBase],
  data: () => ({
    list: [],
    body: null,
    selectorData: [{value: 'Объекты ролей'}],
    currentSelect: 'Объекты ролей',
    visibleSidebarFilter: false,
    visibleSidebarTableSettings: false,
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.sKey = 'roleId';
    this.vKey = this.id;
    this.getById();

    const accessParams = { module: 'security', object: 'roles' };
    const tabMap = {
      'tab_common_information_roles': '1',
      'tab_relation_roles': '3',
    };
    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("роли", `роль ${this.body.name}`);
    },
    getById() {
      if (this.$refs.characteristics)
        this.$refs.characteristics.clean();

      RolesApi.getById(this.id)
          .then(res => {
            res.roleKeycloakName = res.name
            this.body = res;
            suffix.next(' ' + res.name);
          }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristic = this.$refs.characteristics.formatCharacteristicsCopy();
      characteristic.menuItems = this.$refs.characteristics.menuItemsList
      RolesApi.save(characteristic).then(() => {
        this.getById();
      })
    },
    deleteObject() {
      RolesApi.delete(this.body.name).then(res => {
        this.$router.go(-1);
      })
    }
  }
})
