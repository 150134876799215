
import {defineComponent} from "vue";
import HealthModelRuleCharacteristics from "@/components/health-model-rule/HealthModelRuleCharacteristics.vue";
import {GroupsApi} from "@/api/groups.api";
import {HealthModelRuleApi} from "@/api/health-model-rule.api";
import EditForm from "@/components/base/EditForm.vue";

export default defineComponent ({
  name: "HealthModelRuleAdd",
  components: {HealthModelRuleCharacteristics, EditForm},
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      if (this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.data;
      if (characteristics.metricLimits !== null
          && characteristics.metricLimits[0].metricId === null) {
        characteristics.metricLimits = null;
      }
      HealthModelRuleApi.save(
          characteristics
      ).then((res) => {
        this.route(res.id);
      });
    },
    route(id) {
      this.$refs.characteristics.cleanWarning();
      this.$router.replace("/directory/health-model-rule/" + id);
    },
  },
})
