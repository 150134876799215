
import {Options, Vue} from "vue-class-component";
import SupportTeamList from "@/components/support-teams/SupportTeamList.vue";
import {SupportTeamApi} from "@/api/supportTeam.api";
import {Field} from "@/types/fields";
import {getSupportTeamData, supportTeamFields} from "@/consts/fields/support-team.fields";
import {SupportTeamListData} from "@/types/supportTeam";
import TableSettings from "@/components/base/TableSettings.vue";
import SupportTeamsFilter from "@/components/support-teams/SupportTeamsFilter.vue";
import {SupportTeamsFilterImp} from "@/components/classes/SupportTeamsFilterImp";
import NSIBase from "@/classes/NSIBase.vue";
import {setVisibleToFieldsInTable, supportTeamListDtoSettings$} from "@/consts/settings";
import {takeWhile} from "rxjs/operators";
import {currentUser} from "@/consts/currentUser";

@Options({
  components: {
    SupportTeamList,
    TableSettings,
    SupportTeamsFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      currentUser,
      filter: new SupportTeamsFilterImp(),
      totalCount: 0,
      size: 20,
      page: 1,
      search: "",
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      fields: null,
      list: [] as SupportTeamListData[],
      prepareData: getSupportTeamData()
    };
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    routeToAddSupportTeam() {
      this.$router.push("/directory/support-teams/add");
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      SupportTeamApi
              .downloadExcel(filter)
              .then((res) => {
                this.$notification.open({
                  message: new TextDecoder().decode(res.data),
                  style: {
                    width: '600px',
                    marginLeft: `${400 - 600}px`,
                    fontSize: "14px",
                  },
                  placement: 'bottomRight',
                  duration: 3,
                });
              })
              .finally(() => (this.isLoadingExcel = false));
    },
    changeStateVisibleSidebarFilter() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    updateList(value) {
      let column = '';
      if(value.columnKey === 'incidentNumber') {
        column = 'id'
      } else {
        column = value.columnKey;
      }

      let order = '';
      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "id", direction: "desc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    searchMethod(_) {
      return SupportTeamApi
          .applyFilter2(this.defineFilterData, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    supportTeamListDtoSettings$
        .pipe(
            takeWhile(() => !this.fields),
        )
        .subscribe(settings => {
          if(!settings) {
            return;
          }
          if(!settings.fields) {
            this.fields = settings;
            return;
          }
          this.fields = setVisibleToFieldsInTable(supportTeamFields, settings.fields) as Field<SupportTeamListData>[];
        });
  },
})
export default class SupportTeams extends Vue {}
